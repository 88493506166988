import { Box, Typography } from '@mui/material'
import StyledInput from "../../StyledComponents/StyledInput/StyledInput";
import SignPad from '../../SignPad/SignPad'
import styles from '../../StyledComponents/StyledInput/styles'
import { useFormikContext } from 'formik'
import { formStyles } from '../../Form/styles'
import useDisabled from '../../../hooks/useDisabled'
import RequiredMark from "../../RequiredMark/required-mark";
import InputError from "../../InputError/input-error";

const BankAccountInformation = () => {
  const formik = useFormikContext()
  const isDisabled = useDisabled()

  const onCompleteSignature = (base64String) => {
    formik.setFieldValue('signature', base64String)
  }

  const sharedProps = {
    disabled: isDisabled || formik.isSubmitting,
  }

  return (
    <Box sx={{ height: '100%', padding: '8px 8px 0', }}>
      <Box sx={formStyles.formBlock()}>
        <Box sx={formStyles.formBlock(2)}>
          <StyledInput {...sharedProps} label='Bank name' required name="bank_name" placeholder="Bank name" />
          <StyledInput {...sharedProps} label='Bank phone number' type="phone" name="bank_phone" placeholder="Bank phone number" />
          <Box sx={formStyles.formRow(2)}>
            <StyledInput
              {...sharedProps}
              label='Bank Address'
              name="bank_address"
              required
              placeholder="Bank Address"
            />
          </Box>
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput
              {...sharedProps}
              label='City'
              required
              name="bank_city"
              placeholder="City"
            />
            <StyledInput
              {...sharedProps}
              label='State'
              required
              name="bank_state"
              placeholder="State"
            />
            <StyledInput
              {...sharedProps}
              label='Zip Code'
              required
              type="number"
              name="bank_zip_code"
              maxLength={9}
              placeholder="Zip Code"
            />
            <StyledInput
              {...sharedProps}
              label='Country'
              required
              name="bank_country"
              placeholder="Country"
            />
          </Box>
          <StyledInput {...sharedProps} label='Routing number' type="number" name="routing_number" placeholder="Routing number" required />
          <StyledInput {...sharedProps} label='Name of the account' name="bank_account_name" placeholder="Name of the account" required />
          <StyledInput {...sharedProps} label='Account number' name="account_number" placeholder="Account number" required />
          <StyledInput {...sharedProps} label='Bank website' name="bank_website" placeholder="Bank website" />
          <Box gridColumn="1 span/3" sx={formStyles.formBlock(2, 10)}>
            <Typography sx={styles.label}>
              Signature of account holder
              <RequiredMark />
            </Typography>

            <Box>
              <SignPad {...sharedProps} value={formik?.values?.signature} onCompleteSignature={onCompleteSignature} />
              <Typography sx={styles.note}>Tip: Place your mouse cursor into this area, press button and start drawing your sign</Typography>
              <InputError message={formik.touched?.signature && formik.errors?.signature} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BankAccountInformation
