import Header from "../components/Header/header";
import {Box} from "@mui/material";
import MerchantForm from "../components/Form/merchantForm";

const FormPage = () => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#e8e8e8' }}>
      <Header/>
      <MerchantForm/>
    </Box>
  )
}

export default FormPage
