const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    my: 1
  },
  header: {
    backgroundColor: '#e8e8e8',
    px: 2,
    py: 1,
  },
  body: {
    p: 1,
    py: 1,
  }
}

export default styles
