import { Box, Modal } from '@mui/material'
import styles from './styles'


const StyledModal = (props) => {
  const { children, isOpen, onClose, sx } = props

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ ...styles.root, ...sx }}>
        {children}
      </Box>
    </Modal>
  )
}

export default StyledModal
