import { createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'notification';

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    snackbar: {
      opened: false,
      message: '',
      severity: 'error',
    }
  },
  reducers: {
    setSnackbarState: (state, action) => {
      state.snackbar = action.payload;
    },
  },
});

export const {
  setSnackbarState,
} = slice.actions;

export const selectSnackbarState = (state) => state[SLICE_NAME].snackbar


export const showSnackbar = ({ message, severity = 'error'}) => (dispatch) => {
  dispatch(setSnackbarState({
    message,
    severity,
    opened: true,
  }))
};

export const showErrorSnackbar = ({ message }) => (dispatch) => {
  dispatch(showSnackbar({ message, severity: 'error' }))
};

export const showSuccessSnackbar = ({ message }) => (dispatch) => {
  dispatch(showSnackbar({ message, severity: 'success' }))
};

export const closeSnackbar = () => (dispatch) => {
  dispatch(setSnackbarState({
    message: '',
    severity: 'error',
    opened: false,
  }))
};

export const notificationReducer = slice.reducer;
