import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import StyledModal from '../../StyledComponents/StyledModal/StyledModal'
import { selectShouldShowCommentModal, setShouldShowCommentModal } from '../../../store/reducers/modals.reducer'
import { selectFormComment } from '../../../store/reducers/form.reducer'
import styles from './styles'
import StyledButton from '../../StyledComponents/StyledButton/StyledButton'

const CommentModal = () => {
  const isOpenModal = useSelector(selectShouldShowCommentModal)
  const comment = useSelector(selectFormComment)
  const dispatch = useDispatch()
  const closeModal = () => dispatch(setShouldShowCommentModal(false))

  return (
    <StyledModal
      isOpen={isOpenModal}
      onClose={closeModal}
      sx={styles.modal}
    >
      <Box sx={styles.root}>
        <Typography sx={styles.heroText} as={'h3'}>
          Your onboarding request was rejected by administrator
        </Typography>
        <Typography sx={styles.text}>
          Reason:
        </Typography>
        <Box sx={styles.commentBox}>
          <Typography sx={styles.commentText}>
            {comment}
          </Typography>
        </Box>
        <Box sx={styles.closeButtonContainer}>
          <StyledButton onClick={closeModal} sx={styles.closeButton}>
            Close
          </StyledButton>
        </Box>
      </Box>
    </StyledModal>
  )
}

export default CommentModal
