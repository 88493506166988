const modalStyles = {
  root: {
    borderRadius: 2,
    position: 'absolute',
    backgroundColor: 'white',
    minWidth: 400,
    minHeight: 200,
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    p: 1,
  }
}

export default modalStyles
