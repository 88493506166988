import React from 'react'
import {
    Box,
    Drawer as MUIDrawer,
    List,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

function getIsEventKeyboard(
    event
) {
    return event.type === 'keydown'
}

export const Drawer = ({
       isOpen,
       anchor = 'right',
       onDrawerClose,
       drawerHeader,
       drawerItems,
    }) => {
    const classes = useStyles()


    const handleDrawerClose = (e) => {
        if (getIsEventKeyboard(e) && (e.key === 'Tab' || e.key === 'Shift')) {
            return
        }
        onDrawerClose()
    }

    const getDrawerStyles = () => {
        return `${classes.drawer} ${
            isOpen ? classes.drawerOpen : classes.drawerClose
        }`
    }

    return (
        <MUIDrawer
            anchor={anchor}
            open={isOpen}
            onClose={handleDrawerClose}
            variant="persistent"
            className={getDrawerStyles()}
            classes={{
                paper: getDrawerStyles(),
            }}
        >
            <Box className={classes.content}>
                <div role="presentation" onKeyDown={handleDrawerClose}>
                    {drawerHeader}
                    <List style={{ padding: 0 }} onClick={handleDrawerClose}>
                        {drawerItems.map(({ DrawerItem, id }) => (
                            <DrawerItem key={id} />
                        ))}
                    </List>
                </div>
            </Box>
        </MUIDrawer>
    )
}

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
    },
    footerText: {
        alignSelf: 'center',
        marginBottom: 32,
        fontSize: 14,
        color: '#7E7E7E',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: '1s',
        }),
    },
    drawer: {
        display: 'flex',
        top: '0',
        overflowX: 'hidden',
    },
    drawerOpen: {
        width: 220,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(9),
    },
}))
