import React from 'react'
import { Typography } from '@mui/material'

const InputError = ({ message }) => message && (
        <Typography
            gridArea="error"
            sx={{
                textAlign: 'right',
                fontSize: 14,
                color: 'red',
            }}
        >
            {message}
        </Typography>
    )

export default InputError
