import { FORM_STATUS } from '../../constants'

const navigationPanelStyles = {
  saveButton:  {
    minWidth: 150,
    gridColumn: 2,
  },
  submitButton: {
    gridColumn: 3,
  },
  backButton : {
    gridColumn: 1,
  },
  nextButton : {
    gridColumn: 3,
  },
  commentButton: {
    mx: 2,
  },
  banner: {
    alignItems: 'center',
    justifyContent: 'space-between',
    gridColumn: '2/3',
    gap: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  closeModalButton: {
    gridColumn: 2,
    backgroundColor: '#f14e4e',
  },
  submitModalButton: {
    gridColumn: 3,
    backgroundColor: 'rgb(57,166,59)',
  },
  status: (status) => ({
    ml: 1,
    p: 0.5,
    borderRadius: 1,
    ...(status === FORM_STATUS.PENDING && {
      backgroundColor: '#e7c920',
    }),
    ...(status === FORM_STATUS.REJECTED && {
      color: 'white',
      backgroundColor: 'red'
    }),
    ...(status === FORM_STATUS.APPROVED && {
      color: 'white',
      backgroundColor: '#27ad33',
    })
  }),
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  }
}

export default navigationPanelStyles
