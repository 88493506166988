import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'

import StyledButton from '../StyledComponents/StyledButton/StyledButton'
import StyledModal from '../StyledComponents/StyledModal/StyledModal'

import { selectFormComment, selectFormStatus, submitFormData } from '../../store/reducers/form.reducer'
import { showErrorSnackbar } from '../../store/reducers/notification.reducer'

import styles from './styles'
import { FORM_STATUS } from '../../constants'
import { selectDocumentsErrors, setDocumentTouchedById } from '../../store/reducers/documents.reducer'
import { STEP_TYPES } from '../../constants/typeMappings'
import useDisabled from '../../hooks/useDisabled'
import { setShouldShowCommentModal } from '../../store/reducers/modals.reducer'
import { selectIsNotApplicableFormValid } from '../../store/reducers/beneficiaryOwners.reducer'


const NavigationPanel = (props) => {
  const { activeStep, onStepChange, steps } = props
  const [isOpenModal, setIsOpenModal] = useState(false)
  const formik = useFormikContext()

  const formStatus = useSelector(selectFormStatus)
  const isNotApplicableFormValid = useSelector(selectIsNotApplicableFormValid)
  const documentsErrors = useSelector(selectDocumentsErrors)
  const documentsTabHasErrors = Object.keys(documentsErrors).length
  const formComment = useSelector(selectFormComment)
  const dispatch = useDispatch()
  const isDisabled = useDisabled()

  const handleOpenComment = () => {
    dispatch(setShouldShowCommentModal(true))
  }

  const handleOnStepChange = (direction) => {
    let updatedStep = activeStep + direction

    if (updatedStep < 0) updatedStep = 0
    if (updatedStep >= steps.length) updatedStep = steps.length - 1
    onStepChange(updatedStep)
  }

  const validateFormOnSubmit = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length) {
      dispatch(showErrorSnackbar({
        message: 'Unable to save form with errors. Please, check form fields and try again',
      }))
      return
    }

    setIsOpenModal(true)
  }

  const onSubmitApplication = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length) {
      dispatch(showErrorSnackbar({
        message: 'Unable to save form with errors. Please, check form fields and try again',
      }))
      return
    }

    await dispatch(submitFormData({ data: formik.values }))
    setIsOpenModal(false)
  }

  const onSubmitForm = async () => {
    const formikErrors = await formik.validateForm()

    const documentsTabIndex = steps.findIndex(({ type }) => type === STEP_TYPES.DOCUMENTS)
    const documentsError = documentsTabHasErrors && documentsTabIndex !== -1 && activeStep === documentsTabIndex

    if (Object.keys(formikErrors).length || documentsError) {
      Object.keys(formikErrors).forEach(field => {
        const errorValue = formikErrors[field]
        if (typeof errorValue === 'object' && errorValue !== null) {
          Object.keys(errorValue).forEach((subField) => {
            formik.setFieldTouched(`${field}.${subField}`, true)
          })
        } else {
          formik.setFieldTouched(field, true)
        }
      })

      if (documentsError) {
        Object.keys(documentsErrors).forEach((id) => dispatch(setDocumentTouchedById({ id, payload: true})))
      }

      dispatch(showErrorSnackbar({
        message: 'Unable to save form with errors. Please, check form fields and try again',
      }))

      return
    }

    await formik.submitForm()
    handleOnStepChange(+1)
  }

  const renderButtons = () => {
    if (isDisabled) {
      return (
        <Box sx={styles.banner}>
          <Typography textAlign="center">
            This application has been submitted. Current status:
            <Typography as={'span'} sx={styles.status(formStatus)}>
              {`${formStatus}`}
            </Typography>
          </Typography>
        </Box>
      )
    }

    const renderSaveButton = () => {
      const currentStep = steps[activeStep]

      if (currentStep.type === STEP_TYPES.INTRODUCTION) return null

      return (
          <StyledButton
              sx={styles.saveButton}
              onClick={() => onSubmitForm()}
              disabled={formik.isSubmitting || !isNotApplicableFormValid}
              severity={'submit'}
          >
            Save and Continue
          </StyledButton>
      )
    }


    if (formStatus === FORM_STATUS.REJECTED) {
      return (
        <Box sx={styles.banner}>
          <Typography noWrap>
            This application was
            <Typography as={'span'} sx={styles.status(formStatus)}>
              {`${formStatus}`}
            </Typography>
          </Typography>
          {formComment ? (
              <StyledButton
                sx={styles.saveButton}
                onClick={handleOpenComment}
                severity={''}
              >
                See Reason
              </StyledButton>
          ) : null}
          {renderSaveButton()}
        </Box>
      )
    }

    return (
      <>
        {renderSaveButton()}
      </>
    )
  }

  const renderSubmitButton = () => {
    if (activeStep !== steps.length - 1) {
      return (
        <StyledButton
          sx={styles.nextButton}
          onClick={() => handleOnStepChange(+1)}
          disabled={formik.isSubmitting || !formik.isValid || !isNotApplicableFormValid}
        >
          {/*<NavigateNextIcon/>*/}
          Next Page
        </StyledButton>
      )
    }

    if ([FORM_STATUS.CREATED, FORM_STATUS.REJECTED].includes(formStatus)){
      return (
        <StyledButton
          sx={styles.submitButton}
          onClick={validateFormOnSubmit}
          disabled={formik.isSubmitting || !formik.isValid || documentsTabHasErrors}
        >
          Submit Application
        </StyledButton>
      )
    }

    return null
  }

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', my: 1.5, justifyItems: 'center' }}>
        {!!activeStep &&
          <StyledButton
            sx={styles.backButton}
            onClick={() => handleOnStepChange(-1)}
            disabled={formik.isSubmitting || !isNotApplicableFormValid}
          >
            {/*<NavigateBeforeIcon/>*/}
            Previous Page
          </StyledButton>}
        {renderButtons()}
        {renderSubmitButton()}
      </Box>
      <StyledModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        sx={{ px: 2, py: 2, display: 'flex' }}
      >
        <Box sx={styles.modal}>
          <Typography variant="h6" component="h2" fontWeight={'bold'}>
            Please Confirm Your Documents Submission
          </Typography>
          <Typography sx={{ mt: 2, textAlign: 'justify', mx: 3 }}>
            Once you submit your documents you will not be able to resubmit your
            documents until your application is reviewed.
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 3, mt: 2, justifyItems: 'center' }}>
            <StyledButton
              sx={styles.closeModalButton}
              onClick={() => setIsOpenModal(false)}
              disabled={formik.isSubmitting}
            >
              Cancel
            </StyledButton>
            <StyledButton
              sx={styles.submitModalButton}
              onClick={onSubmitApplication}
              disabled={formik.isSubmitting}
            >
              Submit
            </StyledButton>
          </Box>
        </Box>
      </StyledModal>
      </>
  )
}

export default NavigationPanel
