import {ReactComponent as CryptoWallet} from "../../../assets/images/landing-features/crypto-wallet.svg";
import {ReactComponent as Banking} from "../../../assets/images/landing-features/banking.svg";
import {ReactComponent as Dashboard} from "../../../assets/images/landing-features/dashboard.svg";
import {ReactComponent as Onramp_OffRamp} from "../../../assets/images/landing-features/onramp_offramp.svg";
import {Box} from "@mui/material";
import {FeatureCard} from "./components/feature-card";
import React from "react";
import {makeStyles} from "@mui/styles";
import {SECTION_ID} from "../mappings";
import { COMPANIES } from '../../../hooks/useCompany'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../../store/reducers/tenant.reducer'

export const FeatureSection = () => {
    const classes = useStyles()
    const tenantConfig = useSelector(selectTenantConfig)

    const features = [
        {
            title: `${tenantConfig.companyName} Digital Wallet`,
            Image: CryptoWallet,
            featureList: [
                'Digital Non-Custodial Wallet',
                'Digital Custodial Services',
                'Buy & Sell Digital currencies (Multi-Exchange)',
            ],
            theme: 'light',
            hidden: false,
        },
        {
            title: `${tenantConfig.companyName} Banking`,
            Image: Banking,
            featureList: [
                'Bank Settlement & Reconciliation',
                'Automated NACHA File (ACH)',
                'Debit Card Issuing (Consumer & Business Bank Accounts)',
            ],
            theme: 'dark',
            hidden: tenantConfig.companyName === COMPANIES.THE_BOX_WALLET || tenantConfig.companyName === COMPANIES.ZERO_LEDGER || tenantConfig.companyName === COMPANIES.THE_ZERO_WALLET,
        },
        {
            title: `${tenantConfig.companyName} Dashboard`,
            Image: Dashboard,
            featureList: [
                'Real Time Reporting',
                'Consumer & Institutional Client Onboarding',
                'Regulatory Compliance Engine',
                'Real Time Reporting',
            ],
            theme: 'light',
            hidden: tenantConfig.companyName === COMPANIES.THE_BOX_WALLET || tenantConfig.companyName === COMPANIES.ZERO_LEDGER || tenantConfig.companyName === COMPANIES.THE_ZERO_WALLET,
        },
        {
            title: 'Online & In-Person Digital Onramp & Offramp',
            Image: Onramp_OffRamp,
            featureList: [
                'Direct Digital Payments',
                'Fiat to Digital currency to Fiat',
                'Risk Underwriting Filters',
            ],
            theme: 'light',
            hidden: false,
        },
    ]

    const zeroLedgerFeatures = [
        {
            title: `Merchant Solutions`,
            Image: CryptoWallet,
            featureList: [
                'Multi Payments Gateway Platform',
                'Innovative Credit Facility',
                'Smart Contract Tokenization',
            ],
            theme: 'light',
            hidden: false,
        },
        {
            title: `Software as a Service`,
            Image: Onramp_OffRamp,
            featureList: [
                'Downstream Payments Processing',
                'Multi Currency Support',
                'Fast and Secure Payments Gateway',
            ],
            theme: 'light',
            hidden: false,
        },
    ]

    const zeroWalletFeatures = [
        {
            title: `Zero Wallet Platform`,
            Image: CryptoWallet,
            featureList: [
                'New Age Payments Orchestration Platform',
                'Create Digital Invoices and Receipts',
                'Digital Commerce for eCommerce and POS',
                'Widget, iFrame or API Integration',
            ],
            theme: 'light',
            hidden: false,
        },
        {
            title: `Zero Wallet QR Code`,
            Image: Onramp_OffRamp,
            featureList: [
                'QR Codes (Quick Response)',
                'Create QR Codes (Quick and Easy)',
                'Fast and Frictionless Digital Payments',
                'One Step Payment (Single-Sign-on)',
            ],
            theme: 'light',
            hidden: false,
        },
    ]

    const getFeatures = () => {
        if (tenantConfig.companyName === COMPANIES.ZERO_LEDGER) return zeroLedgerFeatures
        if (tenantConfig.companyName === COMPANIES.THE_ZERO_WALLET) return zeroWalletFeatures
        return features
    }

    return (
        <Box className={classes.featuresSection} id={SECTION_ID.SOLUTIONS}>
            {getFeatures().filter(f => !f.hidden).map((feature) => <FeatureCard feature={feature} />)}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    featuresSection: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 20,
        marginTop: 260,
        justifyItems: 'center',
        flewWrap: 'wrap',
        [theme.breakpoints.down(1260)]: {
            gridTemplateColumns: '1fr',
            marginTop: 72,
        },
    },
}))
