const styles = {
  container: {
    position: 'relative',
    '& .sigCanvas': {
      border: 1,
      borderRadius: 3,
    }
  },
  icon: {
    position: 'absolute',
    transform: 'translate(-30px, 5px)',
    ':hover': {
      cursor: 'pointer',
    }
  }
}

export default styles
