import { applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit'

import { rootReducer } from './root.reducer';


export function initStore(preloadedState) {
  const middlewareEnhancer = applyMiddleware(thunkMiddleware)
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
    enhancers: [middlewareEnhancer],
  })

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }

  return store
}
