import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { sendForm, uploadDocument } from '../../../../api/onboarding'
import DragAndDrop from '../../../DragAndDrop/DragAndDrop'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import StyledButton from "../../../StyledComponents/StyledButton/StyledButton";
import moment from 'moment'
import {
    selectDocumentsErrors,
    selectDocumentsLoading,
    selectDocumentsSubmitting, selectDocumentsTouched,
    setDocumentLoadingById, setDocumentsSubmitting, setDocumentTouchedById
} from "../../../../store/reducers/documents.reducer";
import {useDispatch, useSelector} from "react-redux";
import RequiredMark from "../../../RequiredMark/required-mark";

const Document = ({
  label,
  required,
  id,
  templateLink,
  parentFormikCtx,
  isDisabled,
}) => {
    const MAX_FILES = 10
    const documents = parentFormikCtx?.values?.documents
    const isSubmitting = useSelector(selectDocumentsSubmitting)

    const documentsLoading = useSelector(selectDocumentsLoading)
    const isLoading = documentsLoading[id]
    const documentsTouched = useSelector(selectDocumentsTouched)
    const isTouched = documentsTouched[id]
    const documentsErrors = useSelector(selectDocumentsErrors)
    const error = documentsErrors[id]

    const isFormSubmitting = isSubmitting || Object.values(documentsLoading).some((item) => item === true)

    const dispatch = useDispatch()

    const setDocumentTouched = () => {
        if (isDisabled || isFormSubmitting) return
        dispatch(setDocumentTouchedById({ id, payload: true }))
    }

   const handleUploadFiles = async (files) => {
        if (!files?.length) return
        try {
            let updatedDocumentsList = [...documents]
            dispatch(setDocumentLoadingById({ id, payload: true }))
            const fd = new FormData()
            files.forEach(f => fd.append('files', f))
            const data = await uploadDocument(fd)
            const updatedData = data.map((file) => {
                const date = moment().format('YYYY DD MM')
                const { business_name, dba } = parentFormikCtx?.values
                const filelabel = `${date} ${label} ${business_name}` + (dba && `dba ${dba}` || '')

                return { ...file, filelabel }
            })

            const currentDocumentIndex = documents.findIndex((doc) => doc.id === id)
            if (currentDocumentIndex === -1) {
                updatedDocumentsList = [...documents, { label, id, files: updatedData }]
            } else {
                const currentDoc = updatedDocumentsList[currentDocumentIndex]
                updatedDocumentsList[currentDocumentIndex] = {...currentDoc, files: [...currentDoc.files, ...updatedData]}
            }

            await sendForm({ documents: updatedDocumentsList })
            parentFormikCtx.setFieldValue('documents', updatedDocumentsList)
        } finally {
            dispatch(setDocumentLoadingById({ id, payload: false }))
        }
    }

    const onDeleteFile = async (link, id) => {
        const currentDocumentIndex = documents?.findIndex((doc) => doc.id === id)
        const updatedDocumentsList = [...documents]
        const currentDoc = updatedDocumentsList[currentDocumentIndex]

        const updatedFilesList = currentDoc?.files?.filter((file) => file.path !== link)
        updatedDocumentsList[currentDocumentIndex] = {...currentDoc, files: updatedFilesList}
        dispatch(setDocumentsSubmitting(true))
        await sendForm({ documents: updatedDocumentsList })
        dispatch(setDocumentsSubmitting(false))
        parentFormikCtx.setFieldValue('documents', updatedDocumentsList)
    }

    const renderFiles = () => {
        let currentDocument = documents.find((doc) => doc.id === id)
        if (!currentDocument) {
            parentFormikCtx?.setFieldValue('documents', [...documents, { label, id, required: required, files: [] }])
            return
        }

        const filesLength = currentDocument?.files?.length || 0

        const renderDragDrop = () => !isDisabled && filesLength < MAX_FILES && (
            <DragAndDrop
                disabled={isDisabled || isFormSubmitting}
                isLoading={isLoading}
                onFileReceived={handleUploadFiles}
                maxFiles={MAX_FILES - filesLength}
                minimized={filesLength}
                error={error}
                isTouched={isTouched}
            />
        )

        return (
            <Box onClick={setDocumentTouched} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Box display="flex" flexDirection="column" gap={1} mt={1}>
                    {currentDocument?.files?.map((doc, index) => doc && (
                        <Box key={`doc-${id}-${index}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 1, backgroundColor: 'white' }}>
                            <Typography>{doc.originalname}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                <IconButton onClick={() => window.open(doc.path)}>
                                    <FileOpenIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => onDeleteFile(doc.path, id)}
                                    disabled={isDisabled || isFormSubmitting}
                                    sx={localStyles.deleteButton}
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
                {renderDragDrop()}
            </Box>
        )
    }

    const renderTemplateButton = () => templateLink && (
        <StyledButton sx={{ maxWidth: '100%' }} onClick={() => window.open(templateLink)}>
            Get Template
        </StyledButton>
    )

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={localStyles.documentContainer}>
                <Typography variant={'h6'} fontSize={16}>
                    {label}
                    {required && <RequiredMark />}
                </Typography>
                {renderTemplateButton()}
                {renderFiles()}
            </Box>
        </Box>
    )
}

const localStyles = {
    documentContainer: {
        backgroundColor: '#e7e7e7',
        padding: '10px 15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },
    deleteButton: {
        '& > svg': {
            fill: '#f14e4e',
        },
        '&.Mui-disabled': {
            '& > svg': {
                opacity: 0.6,
            },
        }
    },
}

export default Document
