import React, { useState } from 'react'
import { Box, Chip, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CardOne from '../../../assets/images/card-1.png'
import CardTwo from '../../../assets/images/card-2.png'


const CardsSections = () => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const renderCard = () => {
    return (
      <>
        <Box className={classes.card}>
          <img src={CardOne} alt='one' />
          <Box padding={'20px 20px 20px 0'}>
            <Typography lineHeight={'29px'} fontWeight={700} fontSize={'24px'} color={'#212133'}>FlagStaff
              Liquor</Typography>
            <Box className={classes.chipsContainer}>
              <Typography fontWeight={600}>Category:</Typography>
              <Chip className={classes.chip} label='Beverages' variant='outlined' />
              <Chip className={classes.chip} label='Consumer Goods' variant='outlined' />
            </Box>
            <Typography fontSize={'14px'} color={'#818181'} lineHeight={'17px'}>
              Description: FlagStaff Liquor sells popular alcoholic beverages to 21+ direct to consumer. Delivery is
              free for purchases above $50.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.card}>
          <img src={CardTwo} alt='one' />
          <Box padding={'20px 20px 20px 0'}>
            <Typography lineHeight={'29px'} fontWeight={700} fontSize={'24px'} color={'#212133'}>Lenna’s Bonsai
              Trees</Typography>
            <Box className={classes.chipsContainer}>
              <Typography fontWeight={600}>Category:</Typography>
              <Chip className={classes.chip} label='Agriculture' variant='outlined' />
              <Chip className={classes.chip} label='Consumer Goods' variant='outlined' />
            </Box>
            <Typography fontSize={'14px'} color={'#818181'} lineHeight={'17px'}>
              Description: Zen your office space or your home with a beautiful bonsai tree! Our trees have been lovingly
              grown and trimmed!
            </Typography>
          </Box>
        </Box>
      </>
    )

  }

  return (
    <Box component={'section'} padding={'30px 0'}>
      <Box className={classes.header}>
        <Typography fontWeight={700} fontSize={34} color={'#007AFF'}>
          Top gift cards in Ukraine
        </Typography>
        <Box className={classes.selectContainer}>
          <Typography fontWeight={500} fontSize={'18px'}>Sort by</Typography>
          <Select
            className={classes.select}
            value={value}
            onChange={handleChange}
            displayEmpty
            renderValue={
              value !== '' ? undefined : () => <p>Merchant Name</p>
            }
          >
            <MenuItem value={1}>Nick</MenuItem>
            <MenuItem value={2}>Bob</MenuItem>
            <MenuItem value={3}>Ted</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box className={classes.cardsContainer}>
        {renderCard()}
      </Box>
    </Box>
  )
}

export default CardsSections

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 0',
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  select: {
    width: '259px',
    color: '#212133!important',
    height: '40px',
    borderRadius: '8px!important',
    border: '1px solid #D1D1D6',
    '& input:focus': {
      outline: 'none',
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '5px',
  },
  chip: {
    background: '#F2F3F7!important',
    color: '#212133!important',
  },
  card: {
    display: 'flex',
    borderRadius: '8px',
    border: '1px solid #F2F3F7',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    gap: '15px',
    alignItems: 'center',
    minHeight: '160px',
    '& img': {
      height: '150px',
      width: '150px',
      objectFit: 'cover',
    },
  },
}))