import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import FooterLogo from '../../assets/footer-logo.png'
import LetterIcon from '../../assets/images/letter-icon.svg'
import PhoneIcon from '../../assets/images/phone-icon.svg'
import LocationIcon from '../../assets/images/location-icon.svg'

const contacts = [
  { title: 'info@zeroledger.co', link: 'mailto:info@zeroledger.co', icon: LetterIcon },
  { title: '+1(817) 3819-0211', link: 'tel:+181738190211', icon: PhoneIcon },
  { title: 'Hurst TX 76053', link: 'https://www.google.com/maps?q=Hurst%20TX%2076053', icon: LocationIcon },
]

const Footer = () => {
  const classes = useStyles()

  const renderContacts = () => {
    return contacts.map((el, ind) => (
      <Box key={ind} className={classes.contact}>
        <img src={el.icon} alt='' />
        <Link
          href={el.link}
          color="#fff"
          underline="none"
        >
          {el.title}
        </Link>
      </Box>
    ))
  }

  return (
    <Box component={'footer'} className={classes.box} backgroundColor="#212133" padding="20px">
      <Box className={classes.footerContainer}>
        <Box className={classes.logo}>
          <img src={FooterLogo} alt='zero wallet' />
          <Typography
            color="#fff"
            fontSize={16}
            fontWeight={500}
          >
            {`All rights reserved Zerø Wallet LLC 2023`}
          </Typography>
        </Box>
        <Box className={classes.contacts}>
          {renderContacts()}
        </Box>
      </Box>
    </Box>
  )
}

export default Footer

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: '100%',
    maxWidth: '1196px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },
  box: {
    display: 'flex',
    flex: '0 0 auto',
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '46px',
    '& img': {
      width: '170px',
      height: '27px',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  contacts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    gap: '13px',
  },

}))