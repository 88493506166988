import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Box, Button, Typography } from '@mui/material'

import styles from "./styles";
import { formStyles } from '../Form/styles'
import StyledInput from '../StyledComponents/StyledInput/StyledInput'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'
import { loginUserWallet } from '../../api/onboarding'
import { showErrorSnackbar } from '../../store/reducers/notification.reducer'
import { returnAxiosError } from '../../utils'

const LoginWalletForm = () => {
  const [loading, setLoading] = useState(false)
  const tenant = useSelector(selectTenantConfig)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      await loginUserWallet({
        login: values.email,
        password: values.password,
      })
    } catch(e) {
      dispatch(showErrorSnackbar({
        message: returnAxiosError(e),
      }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={styles.container}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form style={{
            height: '100%',
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <img src={tenant.logoUrl} alt={'logo'} style={{ height: 50, objectFit: 'contain', alignSelf: 'center' }} />
              <Box sx={{ padding: '8px 8px 0', }}>
                <Typography sx={{ color:'black', fontSize: 20, textAlign: 'center', mt: 2, mb: 2 }}>Login</Typography>
                <Box sx={formStyles.formBlock(1)}>
                  <StyledInput label='Email' name="email" type="email" placeholder="Email" required />
                  <StyledInput label='Password' type="password" name="password" placeholder="******" required />
                </Box>
              </Box>
              <Button
                sx={styles.sendButton}
                variant="contained"
                onClick={handleSubmit}
                disabled={!(props.values.email && props.values.password) || loading}
              >
                Login
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default LoginWalletForm
