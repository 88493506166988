import { createSlice } from '@reduxjs/toolkit'

const SLICE_NAME = 'tenantState';

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    tenantConfig: {
      companyName: null,
      logoUrl: null,
      primaryColor: null,
      secondaryColor: null,
      authClientId: null,
      authDomain: null,
      domain: null,
      apiUrl: null,
      onboardingUrl: null,
    }
  },
  reducers: {
    setTenantConfig: (state, { payload }) => {
      state.tenantConfig.companyName = payload.company_name;
      state.tenantConfig.logoUrl = payload.logo_url;
      state.tenantConfig.primaryColor = payload.primary_color;
      state.tenantConfig.secondaryColor = payload.secondary_color;
      state.tenantConfig.authClientId = payload.auth_client_id;
      state.tenantConfig.authDomain = payload.auth_domain;
      state.tenantConfig.domain = payload.domain;
      state.tenantConfig.apiUrl = payload.api_url;
      state.tenantConfig.onboardingUrl = payload.onboarding_url;
    },
  },
});

export const {
  setTenantConfig,
} = slice.actions;

export const selectTenantConfig = (state) => state[SLICE_NAME].tenantConfig

export const tenantStateReducer = slice.reducer;

