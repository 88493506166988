import moment from 'moment'

export const scrollToElementById = (id) => {
    const element = document.getElementById(id)
    const yOffset = -60
    const y = element.offsetTop + yOffset

    window.scrollTo({top: y, behavior: 'smooth'});
}

export const formatDateWithoutTime = (stringDate) => moment(stringDate).format('MM/DD/YYYY')
