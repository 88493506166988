import {Box} from "@mui/material";
import RegisterWalletForm from '../components/RegisterWallet/registerWalletForm'
import WalletHeader from '../components/WalletHeader/walletHeader'

const RegisterWallet = () => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#e8e8e8' }}>
      <WalletHeader/>
      <RegisterWalletForm/>
    </Box>
  )
}

export default RegisterWallet
