import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_API_URL}/api`

export const nonAuthInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})
