import { Box, Typography } from '@mui/material'
import Section from '../../Section/section'
import StyledInput from '../../StyledComponents/StyledInput/StyledInput'
import { Field, useFormikContext } from 'formik'
import HtmlEditor from '../../HtmlEditor/html-editor'
import { formStyles } from '../../Form/styles'
import useDisabled from '../../../hooks/useDisabled'

const OWNER_TYPES = [
  'Corporation',
  'LLC',
  'Sole Proprietorship',
  'Partnership',
]

const CorporateInformation = () => {
  const isDisabled = useDisabled()
  const formik = useFormikContext()

  const sharedProps = {
    disabled: formik.isSubmitting || isDisabled
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Section label={'Corporate information & ownership'}>
        <Box sx={formStyles.formBlock(2)}>
          <StyledInput
            {...sharedProps}
            label='Ownership type'
            name="ownership_type"
            required
            options={OWNER_TYPES}
            type="select"
            placeholder="Select ownership type"
          />
          <StyledInput
            {...sharedProps}
            label='Legal business name'
            name="business_name"
            required
            placeholder="Legal business name"
          />
          <StyledInput
            {...sharedProps}
            required
            label='Address 1'
            name="business_address_1"
            placeholder="Address"
          />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput
              {...sharedProps}
              required
              label='City'
              name="business_city"
              placeholder="City"
            />
            <StyledInput
              {...sharedProps}
              label='Zip Code'
              type="number"
              name="business_zip_code"
              maxLength={9}
              placeholder="Zip Code"
              required
            />
          </Box>
          <StyledInput {...sharedProps} label='Address 2' name="business_address_2" placeholder="Address" />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput
              {...sharedProps}
              required
              label='State'
              name="business_state"
              placeholder="State"
            />
            <StyledInput
              {...sharedProps}
              required
              label='Country'
              name="business_country"
              placeholder="Country"
            />
          </Box>
          <Box sx={formStyles.formBlock()}>
            <StyledInput
              {...sharedProps}
              label='Federal Tax ID / EIN'
              name="federal_tax_id"
              placeholder="Federal Tax ID / EIN"
              required
            />
            <StyledInput {...sharedProps} label='Corporate email address' required name="corporate_email" placeholder="Corporate Email" />
            <StyledInput {...sharedProps} label='Contact Title' name="contact_title" required placeholder="Contact title" />
            <StyledInput {...sharedProps} label='Contact Name' name="contact_name" required placeholder="Contact Name" />
            <StyledInput {...sharedProps} label='Contact Email' type="email" required name="contact_email" placeholder="Contact Email" />
          </Box>
          <Box>
            <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>General Comments</Typography>
            <Field
              name={'general_comments'}
            >
              {({ field, form }) =>
                <HtmlEditor
                  defaultValue={field.value}
                  {...sharedProps}
                  theme="snow"
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              }
            </Field>
          </Box>
        </Box>
      </Section>
      <Section label={'Trade reference'}>
        <Box sx={formStyles.formBlock(2)}>
          <StyledInput {...sharedProps} label='Name' name="name" placeholder="Name" />
          <StyledInput {...sharedProps} label='Title' name="title" placeholder="Title" />
          <StyledInput {...sharedProps} label='Email address' type="email" name="email" placeholder="Email" />
          <StyledInput
            {...sharedProps}
            label='Phone number'
            name="phone_number"
            type={"phone"}
            placeholder="Phone number"
          />
          <StyledInput {...sharedProps} label='Address 1' name="address_1" placeholder="Address" />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput {...sharedProps} label='City' name="city" placeholder="City" />
            <StyledInput {...sharedProps} label='Zip Code' type="number" name="zip_code" maxLength={9} placeholder="Zip Code" />
          </Box>
          <StyledInput {...sharedProps} label='Address 2' name="address_2" placeholder="Address" />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput {...sharedProps} label='State' name="state" placeholder="State" />
            <StyledInput {...sharedProps} label='Country' name="country" placeholder="Country" />
          </Box>
        </Box>
      </Section>
    </Box>
  )
}

export default CorporateInformation
