import { Box, Typography } from '@mui/material'
import styles from './styles'
import RequiredMark from "../RequiredMark/required-mark";

const Section = (props) => {
  const { children, label, required } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography sx={{ textAlign: 'left', textTransform: 'uppercase', color: '#676767', fontWeight: 'bold', fontSize: 14 }}>
            {label}{required && <RequiredMark />}
        </Typography>
      </Box>
      <Box sx={styles.body}>
        {children}
      </Box>
    </Box>
  )
}

export default Section
