import React, {useState} from 'react'
import {makeStyles} from "@mui/styles";
import styled from "@emotion/styled";
import LandingHeader from "../components/Landing/landing-header";
import {Box} from "@mui/material";
import {Sidebar} from "../components/Sidebar/sidebar";
import {IntroduceSection, FeatureSection, TeamDescriptionSection, ContactSection} from "../components/Landing/sections";
import {LandingFooter} from "../components/Landing/landing-footer";
import {BackgroundImages} from "../components/Landing/background";

export const Landing = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const handleOpenSidebar = () => setIsSidebarOpen(true)
    const handleCloseSidebar = () => setIsSidebarOpen(false)

    return (
        <>
          <Sidebar isOpen={isSidebarOpen} handleClose={handleCloseSidebar} />
          <Layout>
              <LandingHeader handleOpenSidebar={handleOpenSidebar} />
              <LandingContent />
              <LandingFooter />
          </Layout>
        </>
    )
}

const LandingContent = () => {
    const classes = useStyles()

    return (
        <Box className={classes.contentWrapper}>
            <BackgroundImages />
            <Box width="100%" zIndex={3}>
                <IntroduceSection />
                <FeatureSection />
                <TeamDescriptionSection />
                <ContactSection />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 130px 260px',
        boxSizing: 'border-box',
        position: 'relative',
        alignSelf: 'center',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: 1440,
        [theme.breakpoints.down(1260)]: {
            padding: '0 40px 180px',
        },
        [theme.breakpoints.up(1600)]: {
            padding: '0 40px 400px',
        },
    },
}))

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
