import React from 'react'
import {Box, FormLabel, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const FormField = ({
       label = '',
       placeholder,
       name,
       formik,
       required,
       component = 'input',
       type = 'text',
}) => {
    const classes = useStyles();

    const renderInput = () => {
        const inputProps = {
            name,
            type,
            value: formik.values[name],
            onChange: formik.handleChange,
            className: classes.textField,
            placeholder,
        }

        if (component === 'input') {
            return (<input  {...inputProps} />)
        } else if (component === 'textarea') {
            return (<textarea rows={4} {...inputProps} />)
        }
    }

    const renderLabel = () => (
        <Box display="flex" alignItems="center" gap="5px">
            <Typography className={classes.label}>{label}</Typography>
            {!required && <Typography className={classes.label} style={{ opacity: 0.4 }}>{'(not required)'}</Typography>}
        </Box>
)

    return (
        <Box className={classes.fieldWrapper}>
            <FormLabel>
                {renderLabel()}
            </FormLabel>
            {renderInput()}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    fieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        width: '100%',
    },
    label: {
        '&.MuiTypography-root': {
            fontSize: 14,
            color: '#282E32',
        },
    },
    textField: {
        marginTop: 8,
        fontFamily: 'Jeko-Regular',
        padding: '8px 12px',
        borderRadius: 0,
        border: '1px solid #282E32',
        transition: 'all ease 0.2s',
        resize: 'vertical',
        '&:focus': {
            backgroundColor: '#F6F9FC',
        },
        '&:focus-visible': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
}))

export default FormField
