import React from 'react'
import { makeStyles, styled } from '@mui/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import CheckIcon from '@mui/icons-material/Check';

export function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={`${classes.root} ${active ? classes.active : ''} ${
        completed ? classes.completed : ''
      }`}
    >
      {completed ? <CheckIcon color="primary" className={active && classes.outlined}/> : (
          <div className={classes.circle} />
      )}

    </div>
  )
}

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    'color': theme.palette.primary.main,
    '& > div': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  circle: {
    width: 16,
    height: 16,
    border: '2px solid',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#fff',
  },
  completed: {
    '& > div': {
      backgroundColor: '#eaeaf0',
    },
    'zIndex': 1,
    'fontSize': 18,
  },
  outlined: {
    outline: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
  },
}))

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
