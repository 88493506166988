export const style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        p: 2,
        flex: 1,
        maxHeight: '85vh',
        overflow: 'auto',
    },
    generalTitle: {
        fontSize: 20,
        fontFamily: 'Jeko-Black',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000,
        gap: 2,
    },
    title: {
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: 18,
    },
    text: {
        fontWeight: 300,
        textAlign: 'justify',
    },
    controls: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pt: 2,
        pb: 1,
        borderTop: '1px solid #e7e7e7',
    },
}
