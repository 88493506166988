import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, ButtonBase, Typography } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import useDisabled from '../../../hooks/useDisabled'
import StyledTable from '../../StyledComponents/StyledTable/StyledTable'
import {
  setShouldShowBeneficiaryOwnerModal,
  setShouldShowGeneralInstructionsModal,
} from '../../../store/reducers/modals.reducer'
import { removeBeneficiaryOwner, selectBeneficiaryOwners } from '../../../store/reducers/beneficiaryOwners.reducer'
import { beneficiaryOwnerTable } from '../../../constants/table'
import styles from './styles'
import { FORM_MODE, ROW_DATA_TYPES } from '../../../constants/typeMappings'
import { formatDateWithoutTime } from '../../../helpers/helpers'
import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { LsKeys } from '../../../constants/lsKeys'
import { Field, useFormikContext } from 'formik'
import BeneficiaryOwnerForm from './components/BeneficiaryOwnerForm'

const BeneficiaryOwner = () => {
  const beneficiaryOwners = useSelector(selectBeneficiaryOwners)
  const isDisabled = useDisabled()
  const dispatch = useDispatch()
  const { keycloak } = useKeycloak()
  const { values, errors, touched, setFieldValue } = useFormikContext()

  useEffect(() => {
    checkLsKeyForId()
  }, [])

  useEffect(() => {
    setFieldValue('beneficiary_owners', beneficiaryOwners)
  }, [beneficiaryOwners])

  const checkLsKeyForId = () => {
    const id = keycloak.tokenParsed.email
    const lsKey = LsKeys.isInstructionShowed

    const isModalShowed = localStorage.getItem(lsKey)

    if (!isModalShowed) {
      openInstructionsModal()
    } else if (!isModalShowed.includes(id)) {
      openInstructionsModal()
    }
  }

  const onAddNewOwner = () => dispatch(setShouldShowBeneficiaryOwnerModal({ visible: true }))
  const onEditOwner = (openedIndex) => dispatch(setShouldShowBeneficiaryOwnerModal({
    visible: true,
    openedIndex,
    type: FORM_MODE.EDIT,
  }))
  const onRemoveItem = (index) => dispatch(removeBeneficiaryOwner(index))
  const openInstructionsModal = () => dispatch(setShouldShowGeneralInstructionsModal(true))

  const renderColumns = ({ columns }) => {
    return (
        <TableRow sx={styles.tableHead}>
          {columns.map(c => <TableCell align="center" valign={'top'} sx={styles.tableCell}
                                       key={c.field}>{c.label}</TableCell>)}
          <TableCell align="center" key={'action'} sx={styles.tableCell}>
            {/*<ButtonBase*/}
            {/*    disabled={isDisabled}*/}
            {/*    onClick={onAddNewOwner}*/}
            {/*>*/}
            {/*  <AddCircleIcon sx={styles.addIcon}/>*/}
            {/*</ButtonBase>*/}
          </TableCell>
        </TableRow>
    )
  }

  const renderRows = ({ rows, columns }) => {

    const renderValue = (row, column) => {
      const value = row[column.field]
      if (!value) return ''

      switch (column.type) {
        case ROW_DATA_TYPES.DATE_WITHOUT_TIME:
          return formatDateWithoutTime(value)
        default:
          return value
      }
    }

    return rows.map((r, i) => (
        <TableRow
            key={`table-row-${i}`}
            sx={styles.tableRow}
        >
          {columns.map(c => {
            return (
                <TableCell
                    key={`${i}-${c.field}`}
                    sx={styles.tableCell}
                    component="th"
                    scope="row"
                    onClick={() => onEditOwner(i)}
                >
                  {renderValue(r, c)}
                </TableCell>
            )
          })}
          <TableCell>
            <ButtonBase
                disabled={isDisabled}
                onClick={() => onRemoveItem(i)}
            >
              <DeleteIcon sx={styles.deleteIcon}/>
            </ButtonBase>
          </TableCell>
        </TableRow>
    ))
  }

  const renderContent = () => {
    if (values?.is_not_applicable) {
      return (
          <Box sx={{ px: 3 }}>
            <BeneficiaryOwnerForm notApplicable={values?.is_not_applicable}/>
          </Box>
      )
    }

    const renderError = () => touched.beneficiary_owners && errors.beneficiary_owners && (
        <Box display="flex" width="100%" justifyContent="center" mt={2}>
          <Typography color="red">{errors.beneficiary_owners}</Typography>
        </Box>
    )

    return (
        <>
          <StyledTable
              columns={beneficiaryOwnerTable}
              rows={beneficiaryOwners}
              renderRows={renderRows}
              renderColumns={renderColumns}
          />
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {renderAddBeneficiaryOwnerInstruction()}
              <ButtonBase
                disabled={isDisabled}
                onClick={onAddNewOwner}
              >
                <AddCircleIcon sx={styles.addIcon}/>
              </ButtonBase>
            </Box>
          </Box>
          {renderError()}
        </>
    )
  }

  const renderAddBeneficiaryOwnerInstruction = () => {
    return !values?.is_not_applicable && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Typography
              display="flex"
              gap="5px"
              alignItems="center"
          >
            For additional Beneficial Owners Select
            {/*<AddCircleIcon color="primary" />*/}
            {/*Tab*/}
          </Typography>
        </Box>
    )
  }

  return (
      <Box sx={styles.root}>
        <Box sx={{
          mb: 3,
          px: 3,
        }}>
          <Box sx={styles.infoHolder}>
            <Typography sx={styles.title}>
              The information contained in this Certification is sought pursuant to Section 1020.230 of Title 31 of the
              United States Code of Federal Regulations (31 CFR 1020.230)
            </Typography>
            <Button
                onClick={openInstructionsModal}
                variant="outlined"
                color="primary"
                sx={styles.instructionsButton}
            >
              READ GENERAL INSTRUCTIONS
            </Button>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}>
            <Field type="checkbox" name="is_not_applicable"/>
            <Typography sx={{ ml: 1 }}>Not Applicable</Typography>
          </Box>
          {/*{renderAddBeneficiaryOwnerInstruction()}*/}
        </Box>
        {renderContent()}
      </Box>
  )
}

export default BeneficiaryOwner
