import { useEffect, useState } from 'react'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { TIMEZONES } from '../../constants/timezones'
import { formStyles } from '../Form/styles'
import styles from './styles'

import RequiredMark from "../RequiredMark/required-mark";
import InputError from "../InputError/input-error";


const TimeRangePicker = (props) => {
  const { onChange, values = {}, disabled, required, errors = {}, onBlur } = props
  const [fromTime, setFromTime] = useState(values.fromTime)
  const [toTime, setToTime] = useState(values.toTime)
  const [timezone, setTimezone] = useState(values.timezone)

  useEffect(() => {
    onChange({
      fromTime,
      toTime,
      timezone,
    })
  }, [fromTime, toTime, timezone])

  return (
    <Box sx={formStyles.formRow(2)}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={formStyles.formBlock(2)}>
          <Box >
            <Typography>
              From
              {required && <RequiredMark />}
            </Typography>
            <TimePicker
              disabled={disabled}
              value={fromTime}
              onChange={setFromTime}
              renderInput={(params) => <TextField sx={styles.dateInput} onBlur={() => onBlur('open_time')} {...params} />}
            />
            <InputError message={errors?.fromTime} />
          </Box>
          <Box >
          <Typography>
            To
            {required && <RequiredMark />}
          </Typography>
            <TimePicker
              disabled={disabled}
              value={toTime}
              onChange={setToTime}
              renderInput={(params) => <TextField sx={styles.dateInput} onBlur={() => onBlur('close_time')} {...params} />}
            />
            <InputError message={errors?.toTime} />
          </Box>
        </Box>
        <Box>
          <Typography>
            Timezone
            {required && <RequiredMark />}
          </Typography>
          <Select
            disabled={disabled}
            displayEmpty
            value={timezone}
            gridArea="input"
            onChange={e => setTimezone(e.target.value)}
            variant={'outlined'}
            sx={styles.selectContainer}
            onBlur={() => onBlur('timezone')}
          >
            <MenuItem disabled value={""} key={'menu-default'}>{'Select timezone'}</MenuItem>
            {TIMEZONES?.map((o, i) => (
              <MenuItem value={o.value} key={`menu-${i}`}>{o.label}</MenuItem>
            ))}
          </Select>
          <InputError message={errors?.timezone} />
        </Box>
      </LocalizationProvider>
    </Box>
  )
}

export default TimeRangePicker
