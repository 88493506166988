import React from 'react'
import { Icon, Alert, Snackbar } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeSnackbar, selectSnackbarState } from '../../store/reducers/notification.reducer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    whiteSpace: 'break-spaces',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  action: {
    alignItems: 'flex-start',
    padding: '5px 0',
  },
  icon: {
    alignItems: 'center',
  },
}))

const duration = 3000

const CustomSnackBar = () => {
  const { message, opened, severity } = useSelector(selectSnackbarState)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleCloseSnackbar = () => dispatch(closeSnackbar())

  const autoClose = (event, reason) => {
    if (reason !== 'timeout') return

    handleCloseSnackbar()
  }

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={opened}
      autoHideDuration={duration}
      onClose={autoClose}
    >
      <Alert
        severity={severity}
        classes={{
          action: classes.action,
          icon: classes.icon,
        }}
        action={
          <Icon
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
          >
            <Close fontSize='small' />
          </Icon>
        }
      >
        {message}{' '}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackBar
