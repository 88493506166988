const inputStyles = {
  boxSizing: 'border-box',
  border: 1,
  borderRadius: 2,
  borderColor: 'gray',
  py: 0.4,
  px: 1,
  width: '100%',
  fontFamily: 'Jeko-Regular,Roboto,sans-serif',
  fontWeight: 400,
  '& .Mui-disabled': {
    '::placeholder': {
      '-webkit-text-fill-color': 'transparent',
    },
  },
}

const styles = {
  input: {
    ...inputStyles
  },
  selectContainer: {
    ...inputStyles,
    py: 1,
    px: 1,
    '& .MuiSelect-select': {
      fontFamily: 'Jeko-Regular,Roboto,sans-serif',
      p:0
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    outline: 'none',
  },
  inputContainer: {
    '& input': {
      ...inputStyles,
      boxSizing: 'border-box',
      color: 'currentColor',
      minWidth: 0,
      animationName: 'mui-auto-fill-cancel',
      animationDuration: '10ms',
      fontSize: '1rem',
      py: 1.1,
      outline: 'none',
      '::placeholder': {
        color: '#a2a2a2',
      },
      ':disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: 'unset',
        '::placeholder': {
          color: 'transparent',
        },
      },
    }
  },
  dateInput: {
    width: '100%',
    border: 1,
    borderRadius: 2,
    borderColor: 'gray',
    ...inputStyles,
    py: 1.1,
    '& input': {
      px: 0,
      py: 0,
    },
    '& fieldset': {
      border: 'none',
    },
  },
  container: {
    alignSelf: 'inherit',
    height: 'fit-content',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto',
    alignItems: 'baseline',
    gridTemplateAreas: `"label input"
    "error error"`,
  },
  label: {
    textAlign: 'left',
  },
  requiredMark: {
    color: 'red'
  },
  note: {
    fontSize: 12,
    color: '#9b9b9b'
  },
}

export default styles
