import {Box} from "@mui/material";
import LoginWalletForm from '../components/LoginWallet/loginWalletForm'
import WalletHeader from '../components/WalletHeader/walletHeader'
import React from 'react'

const LoginWallet = () => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#e8e8e8' }}>
      <WalletHeader />
      <LoginWalletForm />
    </Box>
  )
}

export default LoginWallet
