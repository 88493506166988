import { useSelector } from 'react-redux'
import { selectFormStatus } from '../store/reducers/form.reducer'
import { FORM_STATUS } from '../constants'

const useDisabled = () => {
  const status = useSelector(selectFormStatus)

  return ![FORM_STATUS.CREATED, FORM_STATUS.REJECTED].includes(status)
}

export default useDisabled
