const button = {
  minWidth: 150,
}

const beneficiaryModalStyles = {
  saveButton: {
    ...button,
  },
  cancelButton: {
    ...button,
    gridColumn: 2,
  },
}

export default beneficiaryModalStyles
