import React  from 'react'
import ReactQuill from 'react-quill'
import styles from './styles'
import { Box } from '@mui/material'

const HtmlEditor = (props) => {
  const { disabled, ...restProps } = props
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
  ]

  return (
    <Box sx={styles.root}>
      <ReactQuill
        formats={formats}
        modules={modules}
        style={{ wordBreak: 'break-all' }}
        readOnly={disabled}
        {...restProps}
      />
    </Box>
  )
}

export default HtmlEditor
