import {Box, Typography} from "@mui/material";
import {ReactComponent as Persons} from "../../../assets/images/persons.svg";
import React from "react";
import {makeStyles} from "@mui/styles";
import {SECTION_ID} from "../mappings";
import { COMPANIES } from '../../../hooks/useCompany'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../../store/reducers/tenant.reducer'

export const TeamDescriptionSection = () => {
    const classes = useStyles()
    const tenantConfig = useSelector(selectTenantConfig)

    const getText = () => {
        if (tenantConfig.companyName === COMPANIES.ZERO_LEDGER) {
            return (
              `We are a team of passionate people whose goal is to improve 
              everyone's life through innovative products to solve your business 
              problems. ${tenantConfig.companyName} is designed for small to medium size companies
              to optimize their performance and minimize operational costs.`
            )
        }
        return (`We are a team of passionate people whose goal is to improve everyone's
                    life through disruptive products. We build great products to solve your
                    business problems. ${tenantConfig.companyName} is designed for small to medium size companies
                    to optimize their performance and minimize operational costs.`)
    }

    return (
        <Box className={classes.teamDescSection} id={SECTION_ID.ABOUT}>
            <Persons className={classes.personsImg} />
            <Box className={classes.textWrapper}>
                <Typography className={classes.teamDescTitle}>{tenantConfig.companyName}</Typography>
                <Typography className={classes.teamDescText}>
                    {getText()}
                </Typography>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    personsImg: {
        [theme.breakpoints.down(1260)]: {
            display: 'none',
        },
    },
    teamDescSection: {
        marginTop: 240,
        display: 'flex',
        [theme.breakpoints.down(1260)]: {
            marginTop: 48,
            justifyContent: 'center',
        },
    },
    teamDescTitle: {
        '&.MuiTypography-root': {
            fontFamily: 'Jeko-Black',
            fontSize: 112,
            lineHeight: '90px',
            marginTop: 100,
            color: '#fff',
            '-webkit-text-stroke': '1px black',
            [theme.breakpoints.down(1260)]: {
                marginTop: 50,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 60,
                lineHeight: '50px',
            },
        },
    },
    teamDescText: {
        marginTop: '45px!important',
        width: '100%',
        maxWidth: 480,
        fontSize: 16,
    },
    textWrapper: {
        marginLeft: 100,
        [theme.breakpoints.down(1260)]: {
            marginLeft: 0,
            textAlign: 'center',
        },
    },
}))
