import { createSlice } from '@reduxjs/toolkit'

const SLICE_NAME = 'documentsState';

export const slice = createSlice({
    name: SLICE_NAME,
    initialState: {
        isSubmitting: false,
        loading: {},
        touched: {},
        errors: {},
    },
    reducers: {
        setDocumentsSubmitting: (state, { payload }) => {
            state.isSubmitting = payload;
        },
        setDocumentLoadingById: (state, { payload }) => {
            state.loading[payload.id] = payload.payload;
        },
        setDocumentErrors: (state, { payload }) => {
            state.errors = payload;
        },
        setDocumentTouchedById: (state, { payload }) => {
            state.touched[payload.id] = payload.payload;
        },
    },
});

export const {
    setDocumentsSubmitting,
    setDocumentLoadingById,
    setDocumentErrors,
    setDocumentTouchedById
} = slice.actions;

export const selectDocumentsLoading = (state) => state[SLICE_NAME].loading
export const selectDocumentsTouched = (state) => state[SLICE_NAME].touched
export const selectDocumentsErrors = (state) => state[SLICE_NAME].errors
export const selectDocumentsSubmitting = (state) => state[SLICE_NAME].isSubmitting

export const documentsStateReducer = slice.reducer;
