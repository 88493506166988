import { Field, useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'

import useDisabled from '../../../hooks/useDisabled'
import StyledInput from "../../StyledComponents/StyledInput/StyledInput";
import HtmlEditor from '../../HtmlEditor/html-editor'

import { formStyles } from '../../Form/styles'

const LocationInformation = () => {
  const formik = useFormikContext()
  const isDisabled = useDisabled()

  const sharedProps = {
    disabled: isDisabled || formik.isSubmitting
  }

  return (
    <Box sx={{ height: '100%', padding: '8px 8px 0', }}>
      <Box sx={formStyles.formBlock(2)}>
        <StyledInput {...sharedProps} label='Business Name (DBA)' name="dba" placeholder="Business Name" required />
          <StyledInput {...sharedProps} label='Contact Name' name="location_contact_name" placeholder="Contact Name" required />
          <StyledInput {...sharedProps} label='Address 1' name="location_address_1" placeholder="Address" required />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput {...sharedProps} label='City' name="location_city" placeholder="City" required />
            <StyledInput {...sharedProps} label='Zip Code' name="location_zip_code" type="number" placeholder="Zip Code" required />
          </Box>
          <StyledInput {...sharedProps} label='Address 2' name="location_address_2" placeholder="Address" />
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput {...sharedProps} label='State' name="location_state" placeholder="State" required />
            <StyledInput {...sharedProps} label='Country' name="location_country" placeholder="Country" required />
          </Box>
          <StyledInput {...sharedProps} label='Business Open Date' type="date" name="open_date" placeholder="Open Date" required />
          <StyledInput {...sharedProps} label='Contact Phone Number' type="phone" name="location_contact_phone_number" placeholder="Contact Phone Number" required />
          <StyledInput {...sharedProps} label='Location Phone Number' required type="phone" name="location_phone_number" placeholder="Location Phone Number" />
          <StyledInput {...sharedProps} label='Customer Service Phone Number' type="phone" name="customer_service_phone_number" placeholder="Customer Service Phone Number" required />
        <StyledInput {...sharedProps} label='Location Email Address' type="email" name="location_email" placeholder="Location Email Address" />
        <Box gridColumn="1 span/2">
          <StyledInput {...sharedProps} label='Official website' name="location_website" placeholder="Official website" required />
        </Box>
        <Box>
            <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>Comments</Typography>
            <Field
              name={'location_comments'}
            >
              {({ field, form }) =>
                <HtmlEditor
                  theme="snow"
                  {...sharedProps}
                  defaultValue={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              }
            </Field>
          </Box>
      </Box>
    </Box>
  )
}

export default LocationInformation
