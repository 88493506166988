import React from 'react'
import StyledModal from '../../StyledComponents/StyledModal/StyledModal'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectShouldShowGeneralInstructionsModal,
    setShouldShowGeneralInstructionsModal
} from '../../../store/reducers/modals.reducer'
import { Box, Button, Typography } from '@mui/material'
import { introductionSections } from '../../../constants/introductionSections'
import { style } from './styles'
import { LsKeys } from "../../../constants/lsKeys";
import { useKeycloak } from "@react-keycloak/web";

const GeneralInstructionsModal = () => {
    const isOpenModal = useSelector(selectShouldShowGeneralInstructionsModal)

    const dispatch = useDispatch()

    const { keycloak } = useKeycloak()

    const closeModal = () => dispatch(setShouldShowGeneralInstructionsModal(false))

    const handleAccept = () => {
        const id = keycloak.tokenParsed.email
        const lsKey = LsKeys.isInstructionShowed

        const isModalShowed = localStorage.getItem(lsKey)

        if (!isModalShowed) {
            localStorage.setItem(lsKey, id)
        } else if (!isModalShowed.includes(id)) {
            localStorage.setItem(lsKey, `${isModalShowed}, ${id}`)
        }

        closeModal()
    }

    return (
        <StyledModal
            isOpen={isOpenModal}
            onClose={closeModal}
        >
            <Box display="grid">
                <Box sx={style.container}>
                    <Typography sx={style.generalTitle}>GENERAL INSTRUCTIONS</Typography>
                    {introductionSections.map(({ title, text }) => (
                        <Section title={title} text={text}/>
                    ))}
                </Box>
                <Box sx={style.controls}>
                    <Button
                        variant="contained"
                        onClick={handleAccept}
                    >
                        OK
                    </Button>
                </Box>
            </Box>
        </StyledModal>
    )
}

export default GeneralInstructionsModal

const Section = ({ title, text }) => (
    <Box sx={style.section}>
        <Typography sx={style.title}>{title}</Typography>
        <Typography sx={style.text} dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
)

