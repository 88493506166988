import * as Yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { PERCENTAGE_VALIDATION } from '../../constants/validations'
import { currencies } from '../../constants/currencies'

export const corporateInformationValidationSchema = Yup.object().shape({
  ownership_type: Yup.string()
      .trim()
      .required('Ownership type is required'),
  business_name: Yup.string()
      .trim()
      .required('Legal business name is required'),
  business_address_1: Yup.string()
    .required('Address is required'),
  business_city: Yup.string()
    .required('City is required'),
  business_zip_code: Yup.string()
      .min(3, 'Zip code too short')
      .max(9, 'Zip code too long')
      .required('Zip code is required'),
  business_address_2: Yup.string(),
  business_state: Yup.string()
    .required('State is required'),
  business_country: Yup.string()
    .required('Country is required'),
  federal_tax_id: Yup.string()
    .required('Tax ID / EIN is required'),
  corporate_email: Yup.string()
      .email('Invalid email format')
    .required('Corporate email is required'),
  contact_title: Yup.string()
      .required('Contact title is required')
    .trim(),
  contact_name: Yup.string()
      .trim()
      .required('Contact name is required'),
  contact_email: Yup.string()
      .email('Invalid email format')
      .required('Contact email is required'),
  general_comments: Yup.string(),
  name: Yup.string(),
  title: Yup.string(),
  email: Yup.string().email('Invalid email format'),
  phone_number: Yup.mixed()
      .test('phone_number', 'Invalid phone number format', (phone_number) => {
        if (!phone_number) return true
        return isValidPhoneNumber(phone_number)
      }),
  address_1: Yup.string(),
  city: Yup.string(),
  zip_code: Yup.string()
      .test('zip_code', 'Zip code too short', (zip_code) => {
        if (!zip_code) return true
        return zip_code.length >= 3
      })
      .max(9, 'Zip code too long'),
  address_2: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
})

export const locationInformationValidationSchema = Yup.object().shape({
  dba: Yup.string().required('Business Name is required'),
  location_address_1: Yup.string().required('Address 1 is required'),
  location_city: Yup.string().required('City is required'),
  location_zip_code: Yup.string()
      .min(3, 'Zip code too short')
      .max(9, 'Zip code too long')
      .required('Zip code is required'),
  location_address_2: Yup.string(),
  location_state: Yup.string().required('State is required'),
  location_country: Yup.string().required('Country is required'),
  open_date: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .required('Business open date is required'),
  customer_service_phone_number: Yup.mixed()
      .required('Customer service phone number is required')
      .test('phone_number', 'Invalid phone number format', (phone_number) => {
        if (!phone_number) return true
        return isValidPhoneNumber(phone_number)
      }),
  location_phone_number: Yup.mixed()
      .required('Location phone number is required')
      .test('phone_number', 'Invalid phone number format', (phone_number) => {
        if (!phone_number) return true
        return isValidPhoneNumber(phone_number)
      }),
  location_email: Yup.string()
    .email('Invalid email format'),
  location_website: Yup.string()
    .required('Official website is required'),
  location_contact_name: Yup.string().required('Contact name is required'),
  location_contact_phone_number: Yup.mixed()
      .required('Contact phone number is required')
      .test('phone_number', 'Invalid phone number format', (phone_number) => {
        if (!phone_number) return true
        return isValidPhoneNumber(phone_number)
      }),
  location_comments: Yup.string(),
})

export const underwritingProfileValidationSchema = Yup.object().shape({
  business_type: Yup.string().required('Business type is required'),
  products_sold: Yup.string().required('Products sold is required'),
  return_policy: Yup.string().required('Return policy is required'),
  avg_ticket: Yup.number().required('Average ticket is required'),
  highest_ticket: Yup.number().required('Highest ticket is required'),
  discount_paid: Yup.string(),
  customer_countries: Yup.string().required('Customers countries are required'),
  // processor_url: Yup.string()
  //       .url('Invalid URL format'),
  // processor_name: Yup.string(),
  // method_of_sales: Yup.object().shape({
  //   cash: Yup.string()
  //       .matches(PERCENTAGE_VALIDATION, 'Wrong percentage value')
  //       .required('Cash % is required'),
  //   bank_to_bank: Yup.string()
  //       .matches(PERCENTAGE_VALIDATION, 'Wrong percentage value')
  //       .required('Bank to bank % is required'),
  //   crypto: Yup.string()
  //       .matches(PERCENTAGE_VALIDATION, 'Wrong percentage value')
  //       .required('Crypto % is required'),
  //   total: Yup.mixed()
  //       .test('total', 'Total should be 100%', (total) => {
  //         return total === 100
  //       })
  // }),
  value_of_products_sold: Yup.object().shape({
    total_sales: Yup.string().required('Total sales is required'),
    currency: Yup.string().required('Currency is required'),
    total_refunds: Yup.string(),
    chargeback_average: Yup.string(),
    total_non_sufficient_funds: Yup.string(),
  }),
  business_work_hours: Yup.object().shape({
    open_time: Yup.date()
        // .required('Open time is required')
        .typeError('Invalid Date')
        .nullable(),
    close_time: Yup.date()
        // .required('Close time is required')
        .typeError('Invalid Date')
        .nullable(),
    timezone: Yup.string()
      // .required('Timezone is required'),
  }),
  high_volume_month:
      Yup.array().min(1, 'High volume month is required').required('High volume month is required')
})

export const beneficiaryOwnerValidationSchema = Yup.object().shape({
  beneficiary_owners:
      Yup.array().test('arr length', 'Beneficiary owners are required', (value) => {
        return value?.length > 0
      })
})

export const bankAccountInformationValidationSchema = Yup.object().shape({
  bank_name: Yup.string()
    .trim()
    .required('Bank name is required'),
  bank_phone: Yup.mixed()
      // .required('Bank phone is required')
      .test('phone_number', 'Invalid phone number format', (phone_number) => {
        if (!phone_number) return true
        return isValidPhoneNumber(phone_number)
      }),
  bank_address: Yup.string()
    .trim()
    .required('Bank address is required'),
  bank_city: Yup.string()
    .trim()
    .required('City is required'),
  bank_zip_code: Yup.string()
    .min(3, 'Zip code too short')
    .max(9, 'Zip code too long')
    .required('Zip code is required'),
  bank_state: Yup.string()
    .trim()
    .required('State is required'),
  bank_country: Yup.string()
    .trim()
    .required('Country is required'),
  bank_account_name: Yup.string()
    .trim()
    .required('Bank account name is required'),
  routing_number: Yup.number()
    .required('Bank routing number is required'),
  account_number: Yup.string()
    .required('Account number is required'),
  signature: Yup.string()
    .required('Signature is required'),
});


export const corporateInformationInitialValues = {
  ownership_type: '',
  business_name: '',
  business_address_1: '',
  business_city: '',
  business_zip_code: '',
  business_address_2: '',
  business_state: '',
  business_country: '',
  corporate_email: '',
  contact_title: '',
  contact_name: '',
  contact_email: '',
  general_comments: '',
  federal_tax_id: '',
  name: '',
  title: '',
  email: '',
  phone_number: '',
  address_1: '',
  city: '',
  zip_code: '',
  address_2: '',
  state: '',
  country: ''
}

export const locationInformationInitialValues = {
  dba: '',
  location_address_1: '',
  location_city: '',
  location_zip_code: '',
  location_address_2: '',
  location_state: '',
  location_country: '',
  open_date: '',
  customer_service_phone_number: '',
  location_phone_number: '',
  location_email: '',
  location_contact_name: '',
  location_contact_phone_number: '',
  location_website: '',
  location_comments: '',
}

export const underwritingProfileInitialValues = {
  business_type: '',
  products_sold: '',
  return_policy: '',
  avg_ticket: '',
  highest_ticket: '',
  discount_paid: '',
  // processor_url: '',
  // processor_name: '',
  customer_countries: '',
  high_volume_month: [],
  // method_of_sales: {
  //   cash: 0,
  //   bank_to_bank: 0,
  //   crypto: 0,
  //   total: 0,
  // },
  value_of_products_sold: {
    total_sales: '',
    total_refunds: '',
    chargeback_average: '',
    total_non_sufficient_funds: '',
    currency: currencies[0],
  },
  business_work_hours: {
    open_time: null,
    close_time: null,
    timezone: '',
  },
}

export const bankAccountInformationInitialValues = {
  bank_name: '',
  bank_address: '',
  bank_city: '',
  bank_state: '',
  bank_country: '',
  bank_zip_code: '',
  bank_phone: '',
  bank_account_name: '',
  routing_number: '',
  account_number: '',
  signature: '',
  bank_website: '',
}

export const documentsInitialValues = {
  documents: [],
}

export const beneficiaryOwnersInitialValues = {
  beneficiary_owners: [],
  is_not_applicable: false,
}
