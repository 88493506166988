import inputStyles from '../StyledComponents/StyledInput/styles'

const timeRangePickerStyles = {
  dateInput: {
    ...inputStyles.dateInput,
  },
  selectContainer: {
    ...inputStyles.selectContainer,
  }
}


export default timeRangePickerStyles
