import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import merchantBg from '../../../assets/images/merhant_bg.png'

const TitleSection = () => {
  const classes = useStyles()

  return (
    <Box className={classes.titleSection} component={'section'}>
      <Box className={classes.container}>
        <Typography fontWeight={700} fontSize={48} color={'#fff'}>
          Merchant Directory
        </Typography>
      </Box>
    </Box>
  )
}

export default TitleSection

const useStyles = makeStyles((theme) => ({
  titleSection: {
    background: '#212133',
    position: 'relative',
    '&:after': {
      content: '""',
      width: '870px',
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      background: `url(${merchantBg}) 0 0/contain no-repeat`,
    },
  },
  container: {
    width: '100%',
    maxWidth: '1196px',
    margin: '0 auto',
    padding: '71px 10px',
  },
}))