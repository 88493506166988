import { createSlice } from '@reduxjs/toolkit';
import { sendForm } from '../../api/onboarding'

const SLICE_NAME = 'beneficiaryOwners';

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    beneficiaryOwners: [],
    isNotApplicableFormValid: true,
  },
  reducers: {
    setBeneficiaryOwners: (state, action) => {
      state.beneficiaryOwners = action.payload;
    },
    setFormValid: (state, { payload }) => {
      state.isNotApplicableFormValid = payload;
    },
  },
});

export const {
  setBeneficiaryOwners,
  setFormValid
} = slice.actions;

export const addBeneficiaryOwner = (data) => async (dispatch, getState) => {
  const beneficiaryOwners = getState()[SLICE_NAME].beneficiaryOwners
  const updatedBeneficiaryOwnersList = [...beneficiaryOwners, data]
  dispatch(setBeneficiaryOwners(updatedBeneficiaryOwnersList))
  await sendForm({ beneficiary_owners: updatedBeneficiaryOwnersList, is_not_applicable: false }, false)
}

export const updateBeneficiaryOwner = (data, index) => async (dispatch, getState) => {
  const beneficiaryOwners = getState()[SLICE_NAME].beneficiaryOwners
  const updatedBeneficiaryOwnersList = [...beneficiaryOwners]
  updatedBeneficiaryOwnersList[index] = data
  dispatch(setBeneficiaryOwners(updatedBeneficiaryOwnersList))
  await sendForm({ beneficiary_owners: updatedBeneficiaryOwnersList }, false)
}

export const setSingleBeneficiaryOwner = (data) => async (dispatch) => {
  const updatedBeneficiaryOwnersList = [data]
  dispatch(setBeneficiaryOwners(updatedBeneficiaryOwnersList))
  await sendForm({ beneficiary_owners: updatedBeneficiaryOwnersList, is_not_applicable: true }, false)
}

export const removeBeneficiaryOwner = (index) => async (dispatch, getState) => {
  const beneficiaryOwners = getState()[SLICE_NAME].beneficiaryOwners
  const updatedBeneficiaryOwnersList = [...beneficiaryOwners]
  updatedBeneficiaryOwnersList.splice(index, 1)
  dispatch(setBeneficiaryOwners(updatedBeneficiaryOwnersList))
  await sendForm({ beneficiary_owners: updatedBeneficiaryOwnersList }, false)
}

export const selectBeneficiaryOwners = (state) => state[SLICE_NAME].beneficiaryOwners;
export const selectIsNotApplicableFormValid = (state) => state[SLICE_NAME].isNotApplicableFormValid;

export const beneficiaryOwnersReducer = slice.reducer;
