export const ROW_DATA_TYPES = {
    DATE_WITHOUT_TIME: 'DATE_WITHOUT_TIME',
}

export const STEP_TYPES = {
    CORPORATE_INFO: 'corporate-information',
    LOCATION_INFO: 'location-information',
    UNDERWRITING_PROFILE: 'underwriting-profile',
    BENEFICIARY_OWNER: 'beneficiary-owner',
    BANK_ACCOUNT_INFORMATION: 'bank-account-information',
    DOCUMENTS: 'documents',
}

export const FORM_MODE = {
    CREATE: 'create',
    EDIT: 'edit',
}
