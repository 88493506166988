import { useState, useEffect } from "react";

export const useScroll = () => {
    const [scrollY, setScrollY] = useState(document.documentElement.scrollTop);

    const listener = e => {
        setScrollY(document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, []);

    return {
        scrollY,
    };
}
