import React from 'react'
import Header from '../components/MerchantDirectory/header'
import styled from '@emotion/styled'
import TitleSection from '../components/MerchantDirectory/sections/title-section'
import CardsSections from '../components/MerchantDirectory/sections/cards-sections'
import Footer from '../components/MerchantDirectory/footer'

const MerchantDirectory = () => {
  return (
    <>
      <Container>
        <Layout>
          <Header />
        </Layout>
        <Content>
          <TitleSection />
          <Layout>
            <CardsSections />
          </Layout>
        </Content>
         <Footer />
      </Container>
    </>
  )
}

export default MerchantDirectory

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1196px;
  box-sizing: border-box;
  padding: 0 10px;
  position: relative;
  margin: 0 auto;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const Content = styled.div`
  flex: 1 0 auto;
`