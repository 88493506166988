import BeneficiaryOwnerModal from './BeneficiaryOwnerModal/BeneficiaryOwnerModal'
import CommentModal from './CommentModal/CommentModal'
import GeneralInstructionsModal from "./GeneralInstructionsModal/GeneralInstructionsModal";

const Modals = () => {

  return (
    <>
      <BeneficiaryOwnerModal />
      <CommentModal />
      <GeneralInstructionsModal />
    </>
  )
}

export default Modals
