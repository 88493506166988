import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

export const DrawerHeader = ({ isOpen, handleCloseSidebar }) => {
    const classes = useStyles()

    const renderControl = () => {
        if (isOpen) {
            return (
                <Button
                    className={classes.controlButton}
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleCloseSidebar}
                >
                    <CloseIcon className={classes.icon} />
                </Button>
            )
        }

        return (
            <Button
                className={classes.controlButton}
                color="inherit"
                onClick={handleCloseSidebar}
            >
                <MenuIcon className={classes.icon} />
            </Button>
        )
    }

    return (
        <HeaderWrapper className={classes.header}>{renderControl()}</HeaderWrapper>
    )
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
    },
    controlButton: {
        width: '100%',
        maxWidth: 72,
        height: 64,
        alignSelf: 'flex-start',
    },
    icon: {
        color: '#505358',
    },
}))

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0;
`
