const commentModalStyles = {
  root: {
    p: 2,
    height: '100%',
  },
  closeButton: {
    justifySelf: 'end',
  },
  modal: {
    minWidth: 500,
    minHeight: 'auto',
    maxHeight: '80vh',
  },
  heroText: {
    fontWeight: 'bold',
    mb: 2,
  },
  text: {
    fontSize: 16,
    mb: 1,
  },
  commentBox: {
    border: 1,
    maxHeight: 400,
    px: 1,
    overflow: 'hidden',
    overflowY: 'scroll',
    mb: 2,
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  commentText: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
}

export default commentModalStyles
