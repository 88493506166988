import React, {useEffect} from 'react'
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material'
import styles from './styles'
import Loader from '../Loader/Loader'
import { showErrorSnackbar } from '../../store/reducers/notification.reducer'
import { useDispatch } from 'react-redux'

const DragAndDrop = (props) => {
  const { onFileReceived, isLoading, disabled, maxFiles, minimized, error, isTouched } = props

  const dispatch = useDispatch()

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    multiple: maxFiles > 1,
    maxFiles,
    onDrop: onFileReceived,
    disabled: disabled,
  });

  useEffect(() => {
    if (!fileRejections.length) return

    dispatch(showErrorSnackbar({
      message: fileRejections[0].errors[0].message,
    }))
  }, [fileRejections])

  const renderContent = () => {
    if (isLoading) {
      return <Loader />
    }

    if (minimized) {
      return <Typography sx={styles.text}>+ Add Document</Typography>
    }

    return (
      <Typography sx={styles.text}>Drag 'n' drop files here, or click to select files</Typography>
    )
  }

  return (
      <>
        <Box
            {...getRootProps({
              sx: styles.container(
                { disabled, minimized, error: error && isTouched }
              ),
            })}
        >
          <input {...getInputProps()} />
          {renderContent()}
        </Box>

      </>
  );
}

export default DragAndDrop
