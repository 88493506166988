import { Box, Link, Typography } from '@mui/material'
import React from "react";
import { COMPANIES } from '../../hooks/useCompany'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'

export const LandingFooter = () => {
  const classes = useStyles()
  const tenantConfig = useSelector(selectTenantConfig)
  const getCurrentYear = new Date().getFullYear()

  const renderPrivacyForBoxWallet = () => {
    return (
      <>
        <Typography
          color="#fff"
          align="center"
          fontSize={14}
        >
          {`All rights reserved ${tenantConfig.companyName} LLC ${getCurrentYear}`}
        </Typography>
        <Box>
          <Link
            href="/box-wallet-terms.pdf"
            target="_blank"
            underline="hover"
            color="#fff"
            marginRight={'10px'}
          >
            Terms & Conditions
          </Link>
          <Link
            color="#fff"
            href="/box-wallet-policies.pdf"
            target="_blank"
            underline="hover"
          >
            Privacy Policy
          </Link>
        </Box>
      </>
    )
  }

  const renderPrivacyForZeroWallet = () => {
    return (
      <>
        <Typography
          color="#fff"
          align="center"
          fontSize={14}
        >
          {`All rights reserved IATM LLC ${getCurrentYear}`}
        </Typography>
        <Box>
          <Link
            href="/zerowallet_terms.pdf"
            target="_blank"
            underline="hover"
            color="#fff"
            marginRight={'10px'}
          >
            Terms & Conditions
          </Link>
          <Link
            color="#fff"
            href="/zerowallet_policy.pdf"
            target="_blank"
            underline="hover"
          >
            Privacy Policy
          </Link>
        </Box>
      </>
    )
  }

  const renderPrivacyForZeroLedger = () => {
    return (
      <>
        <Typography
          color="#fff"
          align="center"
          fontSize={14}
        >
          {`All rights reserved ${tenantConfig.companyName} Co ${getCurrentYear}`}
        </Typography>
        <Box>
          <Link
            color="#fff"
            href="/zeroledger_policy.pdf"
            target="_blank"
            underline="hover"
            marginRight={'10px'}
          >
            Privacy and Cookies Policy
          </Link>
          <Link
            color="#fff"
            href="/zeroledger_terms.pdf"
            target="_blank"
            underline="hover"
          >
            Terms & Conditions
          </Link>
        </Box>
       </>
    )
  }

  const renderLinks = () => {
    switch (tenantConfig.companyName) {
      case COMPANIES.THE_BOX_WALLET:
        return renderPrivacyForBoxWallet()
      case COMPANIES.THE_ZERO_WALLET:
        return renderPrivacyForZeroWallet()
      case COMPANIES.ZERO_LEDGER:
        return renderPrivacyForZeroLedger()
      default:
        return renderPrivacyForZeroLedger()
    }
  }

  return (
    <Box className={classes.box} backgroundColor="#282E32" padding="20px">
      {renderLinks()}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    fontSize: '14px',
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },

}))
