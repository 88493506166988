import { AppBar, Box, Typography } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { paths } from '../../constants/paths'
import headerStyles from './styles'
import StyledButton from '../StyledComponents/StyledButton/StyledButton'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'

const Header = () => {
  const { keycloak } = useKeycloak()
  const tenant = useSelector(selectTenantConfig)

  const handleLogout = () => keycloak.logout({
    redirectUri: `${window.location.origin}/${paths.landing()}`
  })

  return (
    <AppBar
      position="static"
      sx={headerStyles.container}
    >
      <Box sx={{ width: '100%', maxWidth: 1500, py: 2, px: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={tenant.logoUrl} alt={'logo'} style={{ width: 125, objectFit: 'contain' }} />
        <Box sx={{display: 'inline-flex', alignItems: 'center'}}>
          <Typography sx={{ color:'black', fontSize: 14, mr: 5 }}>
            Logged in as <Typography as={'span'} sx={{ fontWeight: 'bold' }}>{keycloak.tokenParsed.name}</Typography>
          </Typography>
          <StyledButton
            sx={{ backgroundColor: tenant.secondaryColor }}
            type="button"
            onClick={handleLogout}
          >
            Logout
          </StyledButton>
        </Box>
      </Box>
    </AppBar>
  );
}

export default Header
