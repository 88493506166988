import CorporateInformation from '../components/Tabs/CorporateInformaion/CorporateInformation'
import LocationInformation from '../components/Tabs/LocationInformation/LocationInformation'
import UnderwritingProfile from '../components/Tabs/UnderwritingProfile/UnderwritingProfile'
import BankAccountInformation from '../components/Tabs/BankAccountInformation/BankAccountInformation'
import Documents from '../components/Tabs/Documents/Documents'
import BeneficiaryOwner from '../components/Tabs/BeneficiaryOwner/BeneficiaryOwner'
import { STEP_TYPES } from './typeMappings'
import {
  bankAccountInformationValidationSchema,
  beneficiaryOwnerValidationSchema,
  corporateInformationValidationSchema,
  locationInformationValidationSchema,
  underwritingProfileValidationSchema,
} from '../components/Form/formData'

export const onboardingFormSteps = [{
  label: 'Corporate information',
  type: STEP_TYPES.CORPORATE_INFO,
  ContentComponent: CorporateInformation,
  validationSchema: corporateInformationValidationSchema,
},{
  label: 'Location information',
  type: STEP_TYPES.LOCATION_INFO,
  ContentComponent: LocationInformation,
  validationSchema: locationInformationValidationSchema,
},{
  label: 'Underwriting Profile',
  type: STEP_TYPES.UNDERWRITING_PROFILE,
  ContentComponent: UnderwritingProfile,
  validationSchema: underwritingProfileValidationSchema,
},{
  label: 'Beneficiary Owner %',
  type: STEP_TYPES.BENEFICIARY_OWNER,
  ContentComponent: BeneficiaryOwner,
  validationSchema: beneficiaryOwnerValidationSchema,
},{
  label: 'Bank account information',
  type: STEP_TYPES.BANK_ACCOUNT_INFORMATION,
  ContentComponent: BankAccountInformation,
  validationSchema: bankAccountInformationValidationSchema,
},{
  label: 'Documents',
  type: STEP_TYPES.DOCUMENTS,
  ContentComponent: Documents,
}]
