import { useDispatch, useSelector } from 'react-redux'
import { selectTenantConfig, setTenantConfig } from '../../store/reducers/tenant.reducer'
import { useEffect } from 'react'
import { getTenant } from '../../api/onboarding'
import defaultTheme from '../../constants/defaultTheme'
import {ThemeProvider} from "@mui/material/styles";

const TenantProvider = ({children}) => {
  const dispatch = useDispatch()
  const tenantConfig = useSelector(selectTenantConfig)

  useEffect(() => {
    processTenant()
  },[])

  const processTenant = async () => {
    const tenant = await getTenant()
    dispatch(setTenantConfig(tenant))
  }

  if (!tenantConfig?.companyName) return null
  return (
    <>
      <ThemeProvider theme={defaultTheme(tenantConfig)}>
        {children}
      </ThemeProvider>
    </>

  )
}

export default TenantProvider
