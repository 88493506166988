import {createTheme} from "@mui/material/styles";

const defaultTheme = (props) => createTheme({
    palette: {
        primary: {
            main: props?.primaryColor,
        },
        secondary: {
            main: props?.secondaryColor,
        }
    },
    typography: {
        fontFamily: ['Jeko-Regular', 'Roboto', 'sans-serif'].join(','),
        allVariants: {
            color: '#282E32',
        },
    },
})

export default defaultTheme
