import { useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Box, ButtonBase } from '@mui/material'
import { Refresh } from '@mui/icons-material';
import styles from './styles'

const SignPad = (props) => {
  const { onCompleteSignature, value, disabled } = props
  const signCanvasRef = useRef(null)

  useEffect(() => {
    if (value && signCanvasRef.current) {
      signCanvasRef.current.fromDataURL(value, { width: 500, height: 200 })
    }
  }, [])

  useEffect(() => {
    onDisable()
  }, [disabled])

  const onDisable = () => {
    if (!signCanvasRef.current) return
    if (disabled) {
      signCanvasRef.current.off()
      return
    }
    signCanvasRef.current.on()
  }

  const handleClearCanvas = () => {
    signCanvasRef.current.clear()
    onCompleteSignature('')
  }

  return (
    <Box sx={styles.container}>
      <SignatureCanvas
        disabled={disabled}
        ref={signCanvasRef}
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
        onEnd={() => onCompleteSignature(signCanvasRef.current.toDataURL())}
      />
      <ButtonBase sx={styles.icon} disabled={disabled} onClick={handleClearCanvas}>
        <Refresh />
      </ButtonBase>
    </Box>
  )
}

export default SignPad
