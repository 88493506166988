import {Box, Typography} from "@mui/material";
import {ReactComponent as ListMarker} from "../../../../assets/images/landing-features/list-marker.svg";
import React from "react";
import {makeStyles} from "@mui/styles";

export const FeatureCard = ({ feature }) => {
    const { title, featureList, Image, theme } = feature
    const classes = useFeatureCardStyles({ isDark: theme === 'dark' })

    return (
        <Box className={classes.featureWrapper}>
            <Box className={classes.container}>
                <Typography className={classes.title}>{title}</Typography>
                <Box className={classes.featureContainer}>
                    <Box className={classes.container} gap="12px" alignSelf="flex-end">
                        {featureList.map((featureText) => (
                            <Box display="flex" gap="8px" >
                                <ListMarker className={classes.marker} />
                                <Typography className={classes.featureText}>
                                    {featureText}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Image className={classes.img} />
                </Box>
            </Box>
        </Box>
    )
}

const useFeatureCardStyles = makeStyles((theme) => ({
    featureWrapper: ({ isDark }) => ({
        display: 'flex',
        flex: 1,
        padding: 30,
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 580,
        minHeight: 320,
        height: 'min-content',
        textAlign: 'left',
        backgroundColor: isDark ? '#282E32' : '#F6F9FC',
    }),
    featureContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    title: ({ isDark }) => ({
        '&.MuiTypography-root': {
            fontSize: 32,
            lineHeight: '36px',
            fontFamily: 'Jeko-Black',
            color: isDark && '#fff',
        },
    }),
    featureText: ({ isDark }) => ({
        '&.MuiTypography-root': {
            color: isDark ? 'rgba(255, 255, 255, 0.8)' : '#58585A',
        },
    }),
    dark: {
        color: '#fff!important',
        backgroundColor: '#282E32',
    },
    marker: {
        fill: theme.palette.primary.main,
        minWidth: 'fit-content',
        margin: 6,
    },
    img: {
        [theme.breakpoints.down('sm')]: {
           alignSelf: 'center',
           margin: '32px 0',
        },
    },
}))

