// const ZERO_LEDGER_ORIGINS = ['https://landing-staging.zeroledger.co', 'https://zeroledger.co']
// const THE_BOX_WALLET_ORIGINS = ['https://theboxwallet.com', 'https://landing-staging.theboxwallet.com']
// const THE_ZERO_WALLET_ORIGINS = ['https://thezerowallet.com']

export const COMPANIES = {
    IATM: 'iatm',
    ZERO_LEDGER: 'ZeroLedger',
    THE_BOX_WALLET: 'BoxWallet',
    THE_ZERO_WALLET: 'ZeroWallet',
}
