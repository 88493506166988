import { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import {Box, Typography, Divider} from "@mui/material";
import moment from 'moment'

import Section from "../../Section/section";
import StyledInput from '../../StyledComponents/StyledInput/StyledInput'
import HtmlEditor from '../../HtmlEditor/html-editor'
import TimeRangePicker from '../../TimeRangePicker/TimeRangePicker'

import useDisabled from '../../../hooks/useDisabled'
import { currencies } from '../../../constants/currencies'

import { formStyles } from '../../Form/styles'
import RequiredMark from "../../RequiredMark/required-mark";
import InputError from "../../InputError/input-error";

const BUSINESS_TYPES = [
  'Retail location',
  'eCommerce',
]

const MONTHS = {
  jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  aug: 'August',
  sept: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December',
}

const UnderwritingProfile = () => {
  const formik = useFormikContext()
  const isDisabled = useDisabled()

  const sharedProps = {
    disabled: formik.isSubmitting || isDisabled
  }

  const getTimeRangeValues = () => {
    const { business_work_hours } = formik.values

    return {
      fromTime: business_work_hours.open_time ? moment(business_work_hours.open_time) : null,
      toTime: business_work_hours.close_time ? moment(business_work_hours.close_time) : null,
      timezone: business_work_hours.timezone,
    }
  }

  const getTimeRangeErrors = () => {
    const { business_work_hours } = formik.errors

    return {
      fromTime: formik.touched?.business_work_hours?.open_time && business_work_hours?.open_time,
      toTime: formik.touched?.business_work_hours?.close_time && business_work_hours?.close_time,
      timezone: formik.touched?.business_work_hours?.timezone && business_work_hours?.timezone,
    }
  }

  const onChangeTimeRange = (data) => {
    formik.setFieldValue('business_work_hours', {
      open_time: data.fromTime || '',
      close_time: data.toTime || '',
      timezone: data.timezone,
    })
  }

  const onBlurTimePicker = (fieldName) => {
    formik.setFieldTouched(`business_work_hours.${fieldName}`, true)
  }

  const renderCheckBoxes = () => {
    return Object.keys(MONTHS)?.map(item => {
      return (
        <Box key={item} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        }}>
          <Field {...sharedProps} type="checkbox" name="high_volume_month" value={item} onBlur={() => formik.setFieldTouched('high_volume_month',true)} />
          <Typography sx={{ ml: 2}}>{MONTHS[item]}</Typography>
        </Box>
      )
    })
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Section label={'General underwriting profile'}>
        <Box sx={formStyles.formBlock()}>
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput
              {...sharedProps}
              label='Business Type'
              name="business_type"
              options={BUSINESS_TYPES}
              type="select"
              placeholder="Select Business Type"
              required
            />
          </Box>
          <Box sx={formStyles.formBlock(2)}>
            <Box>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Products sold
                <RequiredMark />
              </Typography>
              <Field name={'products_sold'}>
                {({ field, form }) =>
                  <HtmlEditor
                    defaultValue={field.value}
                    {...sharedProps}
                    theme="snow"
                    onChange={(value) => {
                      const updatedValue = value.replace('<p><br></p>', '')
                      form.setFieldValue(field.name, updatedValue)
                    }}
                    onBlur={() => formik.setFieldTouched(field.name, true)}
                  />
                }
              </Field>
              <InputError
                  message={formik.touched?.products_sold && formik.errors?.products_sold}
              />
            </Box>
            <Box>
              <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                Return policy
                <RequiredMark />
              </Typography>
              <Field name={'return_policy'}>
                {({ field, form }) =>
                  <HtmlEditor
                    defaultValue={field.value}
                    {...sharedProps}
                    theme="snow"
                    onChange={(value) => {
                      const updatedValue = value.replace('<p><br></p>', '')
                      form.setFieldValue(field.name, updatedValue)
                    }}
                    onBlur={() => formik.setFieldTouched(field.name, true)}
                  />
                }
              </Field>
              <InputError
                  message={formik.touched?.return_policy && formik.errors?.return_policy}
              />
            </Box>
          </Box>
          <Box sx={{ ...formStyles.formBlock(2), alignItems: 'start'}}>
            <Box sx={formStyles.formRow(1)}>
              <Section label={'High Volume Months (check all that apply)'} required>
                  <Box sx={formStyles.formBlock(4)}>
                    {renderCheckBoxes()}
                  </Box>
                <InputError
                    message={formik.touched?.high_volume_month && formik.errors?.high_volume_month}
                />
              </Section>
            </Box>
            <Box sx={{ ...formStyles.formRow(1)}}>
              <Section label={'Business Work Hours'}>
                <TimeRangePicker
                  {...sharedProps}
                  onChange={onChangeTimeRange}
                  values={getTimeRangeValues()}
                  errors={getTimeRangeErrors()}
                  onBlur={onBlurTimePicker}
                  // required
                />
              </Section>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section label={'underwriting profile'}>
        <Box sx={formStyles.formBlock(2)}>
          <Box sx={formStyles.formBlock()}>
            <StyledInput {...sharedProps} label='Average ticket $' type="number" name="avg_ticket" placeholder="AVG Ticket $" required />
            <StyledInput {...sharedProps} label='Highest ticket $' type="number" name="highest_ticket" placeholder="Highest Ticket $" required />
            <StyledInput {...sharedProps} label='Discount Paid' name="discount_paid" placeholder="Discount Paid" />
            {/*<StyledInput {...sharedProps} label='Current Processor Name' name="processor_name" placeholder="Current Processor Name" />*/}
            {/*<StyledInput {...sharedProps} label='Current Processor URL' name="processor_url" placeholder="Current Processor URL" />*/}
            <StyledInput {...sharedProps} label='Which countries are your customers in?' name="customer_countries" placeholder="Which countries are your customers in" required />
          </Box>
          {/*<MethodOfSalesBlock />*/}
          <ProductsSoldBlock />
        </Box>
      </Section>
    </Box>
  )
}

// const MethodOfSalesBlock = () => {
//   const formik = useFormikContext()
//   const isDisabled = useDisabled()
//   const { cash, bank_to_bank, crypto } = formik.values.method_of_sales
//
//   const sharedProps = {
//     disabled: formik.isSubmitting || isDisabled
//   }
//
//   const getFieldName = (name) => `method_of_sales.${name}`
//
//   useEffect(() => {
//     formik.setFieldValue(getFieldName('total'),(+cash + +bank_to_bank + +crypto) || 0)
//   }, [cash, bank_to_bank, crypto])
//
//   const onFocus = () => {
//     formik.setFieldTouched(getFieldName('total'), true)
//   }
//
//   return (
//     <Section label="Method of sales">
//       <Box sx={{ display: 'grid', gap: 1.5 }}>
//         <StyledInput
//           {...sharedProps}
//           label='Cash %'
//           type="percent"
//           name={getFieldName('cash')}
//           onFocus={onFocus}
//           placeholder="0%"
//           required
//         />
//         <StyledInput
//           {...sharedProps}
//           label='Bank to Bank %'
//           type="percent"
//           name={getFieldName('bank_to_bank')}
//           onFocus={onFocus}
//           placeholder="0%"
//           required
//         />
//         <StyledInput
//           {...sharedProps}
//           label='Crypto %'
//           type="percent"
//           name={getFieldName('crypto')}
//           placeholder="0%"
//           onFocus={onFocus}
//           required
//         />
//         <Divider />
//         <StyledInput
//           label='Total'
//           type="number"
//           name={getFieldName('total')}
//           disabled
//           placeholder="0%"
//         />
//       </Box>
//     </Section>
//   )
// }

const ProductsSoldBlock = () => {
  const formik = useFormikContext()
  const isDisabled = useDisabled()

  const sharedProps = {
    disabled: formik.isSubmitting || isDisabled
  }

  const getFieldName = (name) => `value_of_products_sold.${name}`

  return (
      <Section label="Products sold">
        <Box sx={{ display: 'grid', gap: 1.5 }}>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <StyledInput
                {...sharedProps}
                label='Monthly Total sales'
                type="number"
                name={getFieldName('total_sales')}
                placeholder={'0'}
                required
            />
            <StyledInput
                {...sharedProps}
                options={currencies}
                type="select"
                name={getFieldName('currency')}
                style={{ display: 'flex', width: '100%', maxWidth: 85 }}
                placeholder={'Select Currency'}
                required
            />
          </Box>
          <StyledInput
            {...sharedProps}
            label='Percentage % Monthly Average Chargebacks'
            type="percent"
            name={getFieldName('chargeback_average')}
            placeholder={'%'}
            symbol={'%'}
          />
          <StyledInput
              {...sharedProps}
              label='Monthly Refunds'
              type="number"
              name={getFieldName('total_refunds')}
              placeholder={''}
          />
          <StyledInput
              {...sharedProps}
              label='Percentage % Number of Declines'
              type="percent"
              name={getFieldName('total_non_sufficient_funds')}
              placeholder={'%'}
              symbol={'%'}
          />
        </Box>
      </Section>
  )
}

export default UnderwritingProfile
