import { combineReducers } from 'redux';

import { formReducer } from './reducers/form.reducer'
import { notificationReducer } from './reducers/notification.reducer'
import { modalsReducer } from './reducers/modals.reducer'
import { beneficiaryOwnersReducer } from './reducers/beneficiaryOwners.reducer'
import { documentsStateReducer } from './reducers/documents.reducer'
import { tenantStateReducer } from './reducers/tenant.reducer'


export const rootReducer = combineReducers({
  form: formReducer,
  notification: notificationReducer,
  modals: modalsReducer,
  beneficiaryOwners: beneficiaryOwnersReducer,
  documentsState: documentsStateReducer,
  tenantState: tenantStateReducer,
});
