import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from 'react-redux'
import keycloak from "./helpers/keycloak"
import { AppRouter } from './routes'
import 'react-quill/dist/quill.snow.css'
import 'react-phone-number-input/style.css'
import './App.css';
import { onTokensReceived } from './utils'
import { initStore } from './store'
import CustomSnackBar from './components/Snackbar/Snackbar'
import Modals from './components/Modals'
import TenantProvider from './components/TenantProvider/TenantProvider'

function App() {
  const store = initStore();

  return (
    <div className="App">
      <Provider store={store}>
        <TenantProvider>
          <ReactKeycloakProvider
            authClient={keycloak}
            onTokens={onTokensReceived}
          >
            <CustomSnackBar />
            <Modals />
            <AppRouter />
          </ReactKeycloakProvider>
        </TenantProvider>
      </Provider>
    </div>
  );
}

export default App;
