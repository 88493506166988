const icons = {
  '&:hover': {
    cursor: 'pointer',
  }
}

const tableCell = {
  p: 1,
}

const beneficiaryOwnerStyles = {
  root: {
    height: '100%',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 14,
  },
  addIcon: {
    ...icons,
    color: '#1b73bb',
    fontSize: '50px',
    ':hover': {
      color: '#104672',
    },
  },
  deleteIcon: {
    ...icons,
    color: 'red',
    ':hover': {
      color: '#bb0000',
    },
  },
  tableCell: {
    ...tableCell,
    cursor: 'pointer',
    textAlign: 'center',
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
    ...tableCell,
  },
  tableHead: {
    backgroundColor: '#fafafa',
  },
  infoHolder: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  instructionsButton: {
    fontWeight: 600,
  },
}

export default beneficiaryOwnerStyles
