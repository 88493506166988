import {useEffect,useState} from "react";
import {SECTION_ID} from "../components/Landing/mappings";

const useActiveSections = (scrollY) => {
    const [activeSectionId, setActiveSectionId] = useState(SECTION_ID.HOME)

    const homeSection = document.getElementById(SECTION_ID.HOME)
    const solutionsSection = document.getElementById(SECTION_ID.SOLUTIONS)
    const aboutSection = document.getElementById(SECTION_ID.ABOUT)
    const contactSection = document.getElementById(SECTION_ID.CONTACT)

    const calculateActiveSection = (scrollY) => {
        const sections = [homeSection, solutionsSection, aboutSection, contactSection]

        sections.forEach((section) => {
            if (!section) return;

            const { id, offsetTop, offsetHeight } = section
            if (scrollY >= offsetTop - 200 &&
                scrollY <= offsetTop + offsetHeight) {
                setActiveSectionId(id)
            }
        })
    }

    useEffect(() => {
        calculateActiveSection(scrollY)
    },[scrollY])

    return {
        activeSectionId,
    }
}

export default useActiveSections
