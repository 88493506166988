import { useDispatch, useSelector } from 'react-redux'
import StyledModal from '../../StyledComponents/StyledModal/StyledModal'

import {
  selectShouldShowBeneficiaryOwnerModal,
  setShouldShowBeneficiaryOwnerModal,
} from '../../../store/reducers/modals.reducer'
import BeneficiaryOwnerForm from '../../Tabs/BeneficiaryOwner/components/BeneficiaryOwnerForm'
import { Box } from '@mui/material'

const BeneficiaryOwnerModal = () => {
  const { visible, data } = useSelector(selectShouldShowBeneficiaryOwnerModal)
  const { type, openedIndex } = data
  const dispatch = useDispatch()

  const closeModal = () => dispatch(setShouldShowBeneficiaryOwnerModal({ visible: false }))

  return (
      <StyledModal
          isOpen={visible}
          onClose={closeModal}
      >
        <Box sx={{ p: 3 }}>
          <BeneficiaryOwnerForm type={type} openedIndex={openedIndex} closeModal={closeModal} />
        </Box>
      </StyledModal>
  )
}

export default BeneficiaryOwnerModal
