const dragAndDropStyles = {
  container: ({ disabled, minimized, error }) => ({
    px: 1,
    py: 1,
    border: 1,
    borderStyle: 'dashed',
    backgroundColor: '#e7e7e7',
    display: 'flex',
    alignItems: 'center',

    opacity: disabled && 0.4,
    mt: minimized && 1,
    height: minimized ? 'initial' : '100%',
    borderColor: error && '#FF0000',
  }),
  text: {
    userSelect: 'none',
    textAlign: 'center',
    width: '100%',
  }
}

export default dragAndDropStyles
