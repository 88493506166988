import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material'

const Loader = ({ fullScreen }) => {
  return (
    <Box sx={{
      width: '100%',
      height: fullScreen ? '100vh' : '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress />
    </Box>
  )
}

export default Loader
