export const FORM_STATUS = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

export const ZERO_LEDGER_COMPANY_NAME = 'Zerø Ledger'
export const ZERO_WALLET_COMPANY_NAME = 'Zerø Wallet'
export const IATM_COMPANY_NAME = 'iATM.app'
export const THE_BOX_WALLET_COMPANY_NAME = 'Box Wallet'

export const REDIRECT_ADMIN = 'https://admin.thezerowallet.com'
export const REDIRECT_CREATE_WALLET = 'https://znftmarket.com/signup-email'
export const REDIRECT_LOGIN_TO_WALLET = 'https://znftmarket.com/login'

export const requiredDocuments = [
  {
    label: 'Articles of Incorporation / Certificate of Formation',
    id: 'incorporation_formation',
    required: true,
  },
  {
    label: 'Bank letter confirming account or corporate check',
    id: 'bank_letter_or_corporate_check',
    required: true,
  },
  {
    label: 'Owner(s) passport / US driver`s license / government issued ID',
    id: 'passport_/_drivers_license_/_government_issued_ID',
    maxFiles: 3,
    required: true,
  },
  {
    label: 'Company\'s Proof of Address (Statement that matches address)',
    id: 'owner_utility_bill',
    required: true,
  },
  {
    label: 'Authorized Signer(s) valid passport / US driver`s license / government issued ID',
    id: 'authorized_signers_valid_passport_/_drivers_license_/_government_issued_ID',
  },
  {
    label: 'Beneficial Ownership Form (25% or more ownership)',
    id: 'beneficial_ownership_form',
    templateLink: 'https://storage.googleapis.com/cryptounity-1/Beneficial_Ownership_Form_CDD_APPENDIX_A_R6.7.docx',
    required: true,
  },
  {
    label: 'Owner\'s Proof of Address (Statement that matches address)',
    id: 'unity_bill_for_company',
    required: true,
  },
  {
    label: 'List of countries and states in which company operates',
    id: 'list_of_states_in_which_company_operates',
  },
  {
    label: 'Proof of Domain Ownership',
    id: 'proof_of_domain_ownership',
  },
  {
    label: 'Business Licenses (if applicable)',
    id: 'business_licenses',
  },
  {
    label: 'Tax ID and Company registration number',
    id: 'EIN_SS-4_form',
  },
]
