export const introductionSections = [
    {
        title: 'What is this form?',
        text: '<p>&emsp;&emsp;To help the government fight financial crime, Federal regulation requires certain financial institutions to obtain, verify, and record information about the beneficial owners of legal entity customers. Legal entities can be abused to disguise involvement in terrorist financing, money laundering, tax evasion, corruption, fraud, and other financial crimes. Requiring the disclosure of key individuals who own or control a legal entity (i.e., the beneficial owners) helps law enforcement investigate and prosecute these crimes.</p>',
    },
    {
        title: 'Who has to complete this form?',
        text: '<p>&emsp;&emsp;This form must be completed by the person opening a new account on behalf of a legal entity with any of the following U.S. financial institutions:</p><ul><li> a bank or credit union;</li><li> a broker or dealer in securities;</li><li> a mutual fund; </li><li>a futures commission merchant;</li><li> an introducing broker in commodities.</li></ul><p>&emsp;&emsp;For the purposes of this form, a legal entity includes a corporation, limited liability company, or other entity that is created by a filing of a public document with a Secretary of State or similar office, a general partnership, and any similar business entity formed in the United States or aforeign country. Legal entity does not include sole proprietor ships, unincorporated associations,or natural persons opening accounts on their own behalf.</p>',
    },
    {
        title: 'What information do I have to provide?',
        text: '<p>&emsp;&emsp;This form requires you to provide the name, address, date of birth and Social Security number(or passport number or other similar information, in the case of Non-U.S. Persons) for the following individuals (i.e., the beneficial owners):</p><ul><li>Each individual, if any, who owns, directly or indirectly, 25 percent or more of the equity interests of the legal entity customer (e.g., each natural person that owns 25 percent ormore of the shares of a corporation); </li><li>An individual with significant responsibility for managing the legal entity customer (e.g.,a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, ManagingMember, General Partner, President, Vice President, or Treasurer).</li></ul>',
    },
]
