import * as Yup from "yup";
import { PERCENTAGE_VALIDATION } from "../../../constants/validations";
import moment from "moment";

export const validationSchema = (getConditionalRequiredValidation, is_not_applicable) => Yup.object().shape({
  first_name: Yup.string()
      .trim()
      .required('First Name is required'),
  last_name: Yup.string()
      .trim()
      .required('Last Name is required'),
  role: Yup.string()
      .trim()
      .test('conditionalRequired', 'Role is required',
          (value) => (!is_not_applicable && value) || (is_not_applicable && !value) || (is_not_applicable && value)),
  percentage_of_ownership: Yup.string()
      .matches(PERCENTAGE_VALIDATION, 'Wrong percentage value')
      .test('conditionalRequired', 'Ownership % is required',
          (value) => (!is_not_applicable && value) || is_not_applicable),
  home_address: Yup.string()
      .trim()
      .required('Home Address is required'),
  city: Yup.string()
      .trim()
      .required('City is required'),
  state: Yup.string()
      .trim()
      .required('State is required'),
  zip: Yup.string()
      .min(5, 'Zip Code too short')
      .max(9, 'Zip Code too long')
      .required('Zip Code is required'),
  mailing_address: Yup.string()
      .when('same_as_home_address', getConditionalRequiredValidation('Mailing address')),
  mailing_city: Yup.string()
      .when('same_as_home_address', getConditionalRequiredValidation('Mailing city')),
  mailing_state: Yup.string()
      .when('same_as_home_address', getConditionalRequiredValidation('Mailing state')),
  mailing_zip: Yup.string()
      .min(5, 'Zip Code too short')
      .max(9, 'Zip Code too long')
      .when('same_as_home_address', getConditionalRequiredValidation('Mailing ZIP')),
  dob: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .min(moment().subtract(120, 'years').toDate(), 'Invalid Date')
      .max(
          moment().subtract(18, 'years').subtract(1, 'days').toDate(),
          'Beneficiary Owner should be at least 18 y.o.')
      .required('DOB is required'),
  ssn: Yup.string().required('Government Issued ID Number is required'),
})
