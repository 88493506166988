import * as React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { paths } from '../constants/paths'

export function PrivateRoute({ children, isInternal }) {
  const { keycloak } = useKeycloak()

  if (!keycloak?.authenticated) {
    if (!isInternal) {
      keycloak.register({
        redirectUri: `${window.location.origin}/${paths.form()}`
      })
      return <Outlet />
    }
    return <Navigate to={paths.landing()} replace />;
  }

  return children
}
