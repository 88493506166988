const commonButton = {
  color: 'white',
  backgroundColor: '#1b73bb',
  ':hover': {
    color: 'white',
    backgroundColor: '#104672',
  },
}

const submitButton = {
  color: 'white',
  backgroundColor: 'rgb(57,166,59)',
  ':hover': {
    color: 'white',
    backgroundColor: 'rgb(25,126,27)',
  },
}

const deleteButton = {
  color: 'white',
  backgroundColor: '#f14e4e',
  ':hover': {
    color: 'white',
    backgroundColor: '#c54040',
  },
}

const styles = {
  button: (severity) => ({
    maxWidth: 250,
    lineHeight: '1.5rem',
    color: 'white',
    padding: '6px 16px',
    '&.Mui-disabled': {
      opacity: 0.8,
      color: 'rgba(255,255,255,0.6)'
    },
    ...commonButton,
    ...(severity === 'submit' && submitButton),
    ...(severity === 'delete' && deleteButton),
  })
}

export default styles
