import {ReactComponent as Circle} from "../../assets/images/circle.svg";
import {ReactComponent as Star1} from "../../assets/images/star.svg";
import {ReactComponent as Star2} from "../../assets/images/star2.svg";
import React from "react";
import {makeStyles} from "@mui/styles";

export const BackgroundImages = () => {
    const classes = useStyles()
    return (
        <>
            <Circle className={classes.bgImage} style={{ top: 0, right: 98 }} />
            <Star1 className={classes.bgImage} style={{ top: 1766, left: 0 }} />
            <Star2 className={classes.bgImage} style={{ top: 1581, right: 0 }} />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    bgImage: {
        position: 'absolute',
        [theme.breakpoints.down(680)]: {
            display: 'none',
        },
    },
}))
