import { AppBar, Box, Button } from '@mui/material'
import headerStyles from './styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom'

const WalletHeader = () => {
  const navigate = useNavigate()

  return (
    <AppBar
      position="static"
      sx={headerStyles.container}
    >
      <Box sx={{ width: '100%', maxWidth: 1500, py: 2, px: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate('/')}>
          Back
        </Button>
      </Box>
    </AppBar>
  );
}

export default WalletHeader
