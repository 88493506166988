import { useMemo } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {useKeycloak} from '@react-keycloak/web'
import {PrivateRoute} from './PrivateRoute'
import {paths} from "../constants/paths";
import {Landing} from "../pages/landing";
import FormPage from '../pages/form'
import FullscreenLoader from "../components/FullscreenLoader/fullscreen-loader";
import MerchantDirectory from '../pages/merchantDirectory'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../store/reducers/tenant.reducer'
import { COMPANIES } from '../hooks/useCompany'
import RegisterWallet from '../pages/register-wallet'
import LoginWallet from '../pages/login-wallet'

export const AppRouter = () => {
  const { initialized } = useKeycloak()
  const tenantConfig = useSelector(selectTenantConfig)
  const isInternal = useMemo(() =>
    Object.values(COMPANIES).includes(tenantConfig.companyName),
    [tenantConfig])

  if (!initialized) {
    return <FullscreenLoader />
  }

  const renderInternalRoutes = () => {
    if (isInternal) {
      return (
        <>
          <Route exact path={paths.landing()} element={<Landing />}/>
          <Route exact path={paths.merchantDirectory()} element={<MerchantDirectory />}/>
          <Route exact path={paths.registerWallet()} element={<RegisterWallet />}/>
          <Route exact path={paths.loginWallet()} element={<LoginWallet />}/>
        </>
      )
    }
    return (
      <Route path={"*"} element={<Navigate to={paths.form()} />} />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        {renderInternalRoutes()}
        <Route path={paths.form()} element={
          <PrivateRoute isInternal={isInternal}>
            <FormPage />
          </PrivateRoute>
        }/>
      </Routes>
    </BrowserRouter>
  )
}
