import {AppBar, Box, Button, IconButton, useMediaQuery, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom'
import DehazeIcon from '@mui/icons-material/Dehaze';
import {useKeycloak} from '@react-keycloak/web'
import {paths} from '../../constants/paths'
import {SECTION_ID} from "./mappings";
import {useEffect, useRef} from "react";
import {useScroll} from "../../hooks/useScroll";
import {scrollToElementById} from "../../helpers/helpers";
import useActiveSections from "../../hooks/useActiveSections";
import {COMPANIES} from '../../hooks/useCompany'
import {
  REDIRECT_ADMIN, REDIRECT_CREATE_WALLET, REDIRECT_LOGIN_TO_WALLET,
} from '../../constants'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'

const LandingHeader = ({handleOpenSidebar}) => {
  const classes = useStyles()
  const matches = useMediaQuery((theme) => theme.breakpoints.down(1000))
  const headerRef = useRef()
  const navigate = useNavigate()
  const logoRef = useRef()
  const {keycloak} = useKeycloak()

  const {scrollY} = useScroll()
  const {activeSectionId} = useActiveSections(scrollY)
  const tenantConfig = useSelector(selectTenantConfig)

  const header = headerRef.current
  const logo = logoRef.current

  const hideOnboarding = tenantConfig.companyName !== COMPANIES.ZERO_LEDGER


  useEffect(() => {
    if (!header) return;

    if (scrollY > 0.5) {
      header.classList.add(classes.navSticky)
      logo.classList.add(classes.smallLogo)
    } else {
      header.classList.remove(classes.navSticky)
      logo.classList.remove(classes.smallLogo)
    }
  }, [scrollY])

  const handleLogin = () => keycloak.login({
    redirectUri: `${window.location.origin}/${paths.form()}`
  })
  const handleRegister = () => keycloak.register({
    redirectUri: `${window.location.origin}/${paths.form()}`
  })
  const handleLogout = () => keycloak.logout()

  const handleRedirect = () => window.location.replace(tenantConfig.domain === 'https://admin.zeroledger.co' ? REDIRECT_ADMIN : tenantConfig.domain)
  const handleCreateWallet = () => navigate(paths.registerWallet())
  const handleLoginToWallet = () => navigate(paths.loginWallet())

  const scrollTop = () => window.scrollTo({behavior: 'smooth', top: 0})

  const controls = [
    // {
    //   label: 'Home',
    //   id: SECTION_ID.HOME,
    //   onClick: scrollTop
    // },
    {
      label: 'Solutions',
      id: [SECTION_ID.SOLUTIONS, SECTION_ID.HOME],
      onClick: () => scrollToElementById(SECTION_ID.SOLUTIONS)
    },
    {
      label: 'About Us',
      id: [SECTION_ID.ABOUT],
      onClick: () => scrollToElementById(SECTION_ID.ABOUT)
    },
    {
      label: 'Login',
      onClick: handleLoginToWallet,
      hidden: !hideOnboarding,
    },
    {
      label: 'Create Wallet',
      onClick: handleCreateWallet,
      hidden: !hideOnboarding,
    },
    {
      label: 'Contact',
      id: [SECTION_ID.CONTACT],
      onClick: () => scrollToElementById(SECTION_ID.CONTACT)
    },
    {
      label: 'Admin',
      onClick: () => handleRedirect(),
      hidden: hideOnboarding
    },
    // {
    //   label: 'Merchant Directory',
    //   id: SECTION_ID.MERCHANT_DIRECTORY,
    //   onClick: navigateToMerchantDirectory,
    //   hidden: hideMerchantDirectory
    // },
    // {
    //   label: 'Login',
    //   id: SECTION_ID.CONTACT,
    //   onClick: () => scrollToElementById(SECTION_ID.CONTACT)
    // },
    // {
    //   label: 'Onboarding Form',
    //   onClick: navigateToOnboardingForm,
    //   hidden: keycloak.authenticated,
    // },
    {
      label: 'Logout',
      onClick: handleLogout,
      hidden: !keycloak.authenticated || hideOnboarding,
    },
    {
      label: 'Login',
      onClick: handleLogin,
      hidden: keycloak.authenticated || hideOnboarding,
    },
  ]

  const renderControls = () => (matches ? (
    <IconButton style={{width: 40, heigth: 40, marginRight: -16}} onClick={handleOpenSidebar}>
      <DehazeIcon/>
    </IconButton>
  ) : (
    <Box className={classes.buttonsContainer}>
      {controls.map(({label, hidden, onClick, id}) => !hidden && (
        <ControlButton
          className={id?.includes(activeSectionId) && classes.active}
          onClick={onClick}
        >
          {label}
        </ControlButton>
      ))}
      {!keycloak.authenticated && !hideOnboarding && (
        <ControlButton
          onClick={handleRegister}
          variant="contained"
          style={{color: '#fff'}}
        >
          Start Onboarding
        </ControlButton>
      )}
      {/*          {!keycloak.authenticated && (*/}
      {/*              <ControlButton*/}
      {/*                  onClick={() => createTransaction()}*/}
      {/*                  variant="contained"*/}
      {/*                  style={{ color: '#fff' }}*/}
      {/*              >*/}
      {/*                  Create Zerø Wallet*/}
      {/*              </ControlButton>*/}
      {/*          )}*/}
        </Box>
        ))

        return (
        <AppBar className={classes.appBar} position="static" color="transparent" elevation={0} ref={headerRef}>
      {tenantConfig.logoUrl ?
        (
        <img
        className={classes.logoImg}
        onClick={scrollTop}
        src={tenantConfig.logoUrl}
        alt={tenantConfig.companyName}
        ref={logoRef}
        />
        ) :
        (
        <Typography ref={logoRef} fontSize={23} fontWeight={'bold'} color="#000">
      {tenantConfig.companyName}
        </Typography>
        )}
      {renderControls()}
        </AppBar>
        );
      }

      const useStyles = makeStyles((theme) => ({
      navSticky: {
      '&.MuiAppBar-root': {
      backgroundColor: '#fff',
      boxShadow: '0px 3px 10px 0px rgb(38 107 193 / 8%)',
      [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
    },
    },
      appBar: {
      padding: '30px 130px',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'space-between',
      '&.MuiAppBar-root': {
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 10,
      position: 'sticky',
      top: 0,
      [theme.breakpoints.down('sm')]: {
      gap: 16,
    },
    },
      [theme.breakpoints.down(950)]: {
      padding: '16px 40px',
    },
    },
      logoImg: {
      width: 125,
      height: 50,
      transition: 'all ease 0.2s',
      cursor: 'pointer',
      objectFit: 'contain',
    },
      smallLogo: {
      maxWidth: 125,
      height: 25,
    },
      buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: 650,
    },
      button: {
      textTransform: 'none',
      padding: '0 10px',
    },
      active: {
      color: `${theme.palette.primary.main}!important`,
    },
    }))

      const ControlButton = styled(Button)`
      text-transform: none;
      padding: 6px 10px;
      line-height: initial;
      height: 32px;
      font-size: 16px;
      color: #000;
      width: max-content;
      overflow-wrap: initial;
      `

      export default LandingHeader
