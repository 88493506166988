import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import Loader from '../Loader/Loader'
import { sendForm } from '../../api/onboarding'

export const FormHandler = ({ children, activeStep, setActiveStep, validateStep, setCompleted, isDisabled, steps }) => {
    const [isReady, setIsReady] = useState(false)
    const formik = useFormikContext()

    useEffect(() => {
        if (!isReady) return
        (async () => {
            const last_opened_step = steps[activeStep].type
            formik.setFieldValue('last_opened_step', last_opened_step)

            await sendForm({ last_opened_step })
        })()
    }, [activeStep])

    useEffect(() => {
        if (isReady) return
        (async () => {
            if (isDisabled) {
                setIsReady(true)
                return
            }

            const isCompleted = await validateStep(formik)
            if (!isCompleted) {
                setCompleted((prev) => {
                    const lastOpenedStep = formik.values.last_opened_step

                    const lastOpenedStepIndex = steps.findIndex((step) => step.type === lastOpenedStep)

                    if (lastOpenedStepIndex !== -1 && prev[lastOpenedStepIndex]) {
                        setActiveStep(lastOpenedStepIndex)
                    }

                    return {...prev, [activeStep]: false}
                })
                setIsReady(true)
            } else {
                setCompleted((prev) => ({ ...prev, [activeStep]: true }))
            }
        })()
    }, [activeStep])

    useEffect(() => {
          formik.validateForm()
          setCompleted((prev) => ({ ...prev, [activeStep - 1]: true }))
        }, [activeStep])

    return isReady ? children : <Loader />
}
