import React from 'react'
import {Box, CircularProgress, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'
import { useSelector } from 'react-redux'

const FullscreenLoader = () => {
  const classes = useStyles()
  const tenant = useSelector(selectTenantConfig)


  return (
    <Box className={classes.loaderWrapper}>
      {tenant?.logoUrl ? (
        <img className={classes.loaderImg} src={tenant.logoUrl} alt={tenant.companyName}/>
      ): (
        <Typography fontSize={23} fontWeight={'bold'} color="#000">
        {tenant.companyName}
      </Typography>)}
      <CircularProgress/>
    </Box>
  )
}

const useStyles = makeStyles({
  loaderWrapper: {
    width: '100%',
    height: '100vh',
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderImg: {
    maxWidth: 125,
    objectFit: 'contain',
  }
})

export default FullscreenLoader
