import { useDispatch, useSelector } from 'react-redux'
import {
  addBeneficiaryOwner,
  selectBeneficiaryOwners,
  setFormValid,
  setSingleBeneficiaryOwner,
  updateBeneficiaryOwner,
} from '../../../../store/reducers/beneficiaryOwners.reducer'
import * as Yup from 'yup'
import moment from 'moment'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { validationSchema } from '../../../Modals/BeneficiaryOwnerModal/validationSchema'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Box, Typography } from '@mui/material'
import { formStyles } from '../../../Form/styles'
import StyledButton from '../../../StyledComponents/StyledButton/StyledButton'
import styles from '../../../Modals/BeneficiaryOwnerModal/styles'
import StyledInput from '../../../StyledComponents/StyledInput/StyledInput'
import { FORM_MODE } from '../../../../constants/typeMappings'

const BeneficiaryOwnerForm = ({ type, openedIndex, closeModal, notApplicable }) => {
  const beneficialOwners = useSelector(selectBeneficiaryOwners)
  const dispatch = useDispatch()

  const getConditionalRequiredValidation = (requiredFieldName) => (same_as_home_address) =>
      !same_as_home_address ? Yup.string().required(`${requiredFieldName} is required`) : Yup.string()

  const onSubmit = (fields) => {
    const updatedFormData = { ...fields }
    delete updatedFormData.same_as_home_address
    if (fields.dob) updatedFormData.dob = moment(fields.dob).format('YYYY-MM-DD')
    if (fields.same_as_home_address) {
      updatedFormData.mailing_address = updatedFormData.home_address
      updatedFormData.mailing_city = updatedFormData.city
      updatedFormData.mailing_state = updatedFormData.state
      updatedFormData.mailing_zip = updatedFormData.zip
    }

    if (type === FORM_MODE.EDIT && openedIndex !== null) {
      dispatch(updateBeneficiaryOwner(updatedFormData, openedIndex))
    } else if (notApplicable) {
      updatedFormData.role = 'owner'
      updatedFormData.percentage_of_ownership = 100
      dispatch(setSingleBeneficiaryOwner(updatedFormData))
    } else {
      dispatch(addBeneficiaryOwner(updatedFormData))
    }
    closeModal()
  }

  const getInitialValues = () => {
    if (type === FORM_MODE.EDIT && openedIndex !== null) {
      return beneficialOwners[openedIndex]
    }
    if (notApplicable && beneficialOwners[0] && beneficialOwners[0].percentage_of_ownership === 100) {
      return beneficialOwners[0]
    }

    return {
      first_name: '',
      last_name: '',
      role: '',
      percentage_of_ownership: '',
      home_address: '',
      city: '',
      state: '',
      zip: '',
      mailing_address: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zip: '',
      dob: null,
      ssn: '',
      same_as_home_address: false,
    }
  }

  return (
      <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema(getConditionalRequiredValidation, notApplicable)}
          onSubmit={onSubmit}
          validateOnMount
      >
        <BOForm directSubmit={onSubmit} notApplicable={notApplicable} closeModal={closeModal}/>
      </Formik>
  )
}

export default BeneficiaryOwnerForm

const BOForm = ({ directSubmit, notApplicable, closeModal }) => {
  const [isReady, setIsReady] = useState(false)
  const formik = useFormikContext()
  const dispatch = useDispatch()

  const debouncedDirectSubmit = useRef(
      _.debounce(async (values) => {
        await directSubmit(values)
      }, 700),
  ).current

  useEffect(() => {
    setIsReady(true)
  }, [])

  useEffect(() => {
    (async () => {
      if (!notApplicable || !formik.isValid || !isReady) return

      debouncedDirectSubmit(formik.values)
    })()
  }, [formik.values])

  useEffect(() => {
    dispatch(setFormValid(formik.isValid))

    return () => {
      dispatch(setFormValid(true))
    }
  }, [formik?.isValid])

  const renderControls = () => !notApplicable && (
      <Box sx={{ ...formStyles.formBlock(4, 3), mt: 3, justifyItems: 'center' }}>
        <StyledButton onClick={closeModal} severity={'delete'} sx={styles.cancelButton}>
          Cancel
        </StyledButton>
        <StyledButton type="submit" severity={'submit'} sx={styles.saveButton}>
          Save
        </StyledButton>
      </Box>
  )

  return (
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ minWidth: '70vw' }}>
          <Box sx={formStyles.formBlock(2)}>
            <StyledInput
                disabled={formik.isSubmitting}
                label="First Name"
                name="first_name"
                required
                placeholder="First Name"
            />
            {!notApplicable && (<StyledInput
                disabled={formik.isSubmitting}
                label="Role"
                name="role"
                required
                placeholder="Role"
            />)}
            <StyledInput
                disabled={formik.isSubmitting}
                label="Last Name"
                name="last_name"
                required
                placeholder="Last Name"
            />
            {!notApplicable && (<StyledInput
                disabled={formik.isSubmitting}
                label="Percentage % of ownership"
                name="percentage_of_ownership"
                required
                placeholder="%"
                symbol={'%'}
                type="percent"
            />)}
            <Box sx={formStyles.formRow(2)}>
              <StyledInput
                  disabled={formik.isSubmitting}
                  label="Home address"
                  name="home_address"
                  required
                  placeholder="Address"
              />
            </Box>
            <Box sx={formStyles.formBlock(2)}>
              <StyledInput
                  disabled={formik.isSubmitting}
                  label="City"
                  name="city"
                  placeholder="City"
                  required
              />
              <StyledInput
                  disabled={formik.isSubmitting}
                  label="State"
                  name="state"
                  placeholder="State"
                  required
              />
              <StyledInput
                  disabled={formik.isSubmitting}
                  label="Zip Code"
                  type="number"
                  name="zip"
                  maxLength={9}
                  placeholder="Zip Code"
                  required
              />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            }}>
              <Field type="checkbox" name="same_as_home_address"/>
              <Typography sx={{ ml: 2 }}>Same as Home Address</Typography>
            </Box>
            <Box/>
            <Box sx={formStyles.formRow(2)}>
              <StyledInput
                  disabled={formik.isSubmitting || formik.values.same_as_home_address}
                  label="Mailing address"
                  name="mailing_address"
                  placeholder="Address"
                  displayedValue={formik.values.same_as_home_address && formik.values.home_address}
                  required={!formik.values.same_as_home_address}
              />
            </Box>
            <Box sx={formStyles.formBlock(2)}>
              <StyledInput
                  disabled={formik.isSubmitting || formik.values.same_as_home_address}
                  label="City"
                  name="mailing_city"
                  placeholder="City"
                  displayedValue={formik.values.same_as_home_address && formik.values.city}
                  required={!formik.values.same_as_home_address}
              />
              <StyledInput
                  disabled={formik.isSubmitting || formik.values.same_as_home_address}
                  label="State"
                  name="mailing_state"
                  placeholder="State"
                  displayedValue={formik.values.same_as_home_address && formik.values.state}
                  required={!formik.values.same_as_home_address}
              />
              <StyledInput
                  disabled={formik.isSubmitting || formik.values.same_as_home_address}
                  label="Zip Code"
                  type="number"
                  name="mailing_zip"
                  maxLength={9}
                  placeholder="Zip Code"
                  displayedValue={formik.values.same_as_home_address && formik.values.zip}
                  required={!formik.values.same_as_home_address}
              />
            </Box>
            <StyledInput
                disabled={formik.isSubmitting}
                label="DOB"
                name="dob"
                type="date"
                placeholder="DOB"
                required
            />
            <StyledInput
                required
                disabled={formik.isSubmitting}
                label="Government Issued ID Number"
                name="ssn"
                placeholder="Issued ID"
            />
          </Box>
        </Box>
        {renderControls()}
      </Form>
  )
}
