import { Field, useFormikContext } from 'formik'
import { Box, InputBase, TextField, Typography, Select, MenuItem, InputAdornment } from '@mui/material'
import PhoneInput from 'react-phone-number-input/input'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styles from "./styles";
import InputError from "../../InputError/input-error";
import RequiredMark from "../../RequiredMark/required-mark";

const StyledInput = (props) => {
  const {
    label,
    placeholder,
    required,
    maxLength,
    disabled,
    onFocus,
    type,
    options,
    style,
    displayedValue,
    symbol,
    ...restProps
  } = props
  const formik = useFormikContext();

  const handleOnChange = (e, callback) => {
    if (type === 'number' && /[^0-9]/.test(e.target.value)) return

    if (type === 'percent') {
      let percentValue = e.target.value.toString().replace(',', '.')
      if(percentValue && !(percentValue.match(/^\d+\.?\d?\d?$/)
          && parseFloat(percentValue)<=100 && parseFloat(percentValue)>=0)) {
        return
      }
      if (percentValue.startsWith('0') && percentValue.length > 1 || percentValue.startsWith('0.') && percentValue.length > 4) {
        if (!percentValue.includes('.')) percentValue = percentValue.slice(1)
      }
      e.target.value = percentValue
    }

    if (type === 'decimal') {
      let percentValue = e.target.value.toString().replace(',', '.')

      if(percentValue && !(percentValue.match(/^\d+\.?\d?\d?$/)
        && parseFloat(percentValue)>=0)) {
        return
      }

      if (percentValue.startsWith('0') && percentValue.length > 1 || percentValue.startsWith('0.') && percentValue.length > 4) {
        if (!percentValue.includes('.')) percentValue = percentValue.slice(1)
      }
      e.target.value = percentValue
    }

    callback(e)
  }

  const renderLabel = () => {
    if (!label) return null;

    return (
      <Typography
        gridArea="label"
        sx={styles.label}
      >
        {label}{required && <RequiredMark />}
      </Typography>
    )
  }

  const renderError = (meta) => {
    if (!(meta.touched && meta.error)) return null;

    return <InputError message={meta.error} />
  }

  const renderInput = (field, meta) => {
    if (type === 'select') {
      return (
        <Select
          {...field}
          type={type}
          onFocus={onFocus}
          displayEmpty
          gridArea="input"
          onChange={e => handleOnChange(e, field.onChange)}
          disabled={disabled}
          variant={'outlined'}
          sx={styles.selectContainer}
          error={!!(meta.touched && meta.error)}
        >
          {placeholder && <MenuItem disabled value={""} key={'menu-default'}>{placeholder}</MenuItem>}
          {options?.map((o, i) => (
            <MenuItem value={o} key={`menu-${i}`}>{o}</MenuItem>
          ))}
        </Select>
      )
    }
    if (type === 'phone') {
      return (
        <Box sx={styles.inputContainer}>
          <PhoneInput
            {...field}
            gridArea="input"
            type={type}
            disabled={disabled}
            onChange={(value) => {
              formik.setFieldValue(field.name, value || '')
            }}
            placeholder={placeholder}
          />
        </Box>
      )
    }

    if (type === 'date') {
      const setTouched = () => formik.setFieldTouched(field.name, true)

      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            inputFormat="MM/DD/yyyy"
            {...field}
            disabled={disabled}
            openTo="year"
            gridArea="input"
            onOpen={setTouched}
            type={type}
            validationError={true}
            onChange={(date, value) => {
              formik.setFieldValue(field.name, date)
            }}
            renderInput={(params) => <TextField sx={styles.dateInput} onBlur={setTouched} {...params} />}
          />
        </LocalizationProvider>
      )
    }

    if (type === 'password') {
      return (
        <InputBase
          {...field}
          value={disabled && displayedValue || field.value}
          onFocus={onFocus}
          inputProps={{
            maxLength,
          }}
          type={type}
          gridArea="input"
          onChange={e => handleOnChange(e, field.onChange)}
          disabled={disabled}
          placeholder={placeholder}
          sx={styles.input}
          error={!!(meta.touched && meta.error)}
        />
      )
    }

    return (
      <InputBase
        {...field}
        value={disabled && displayedValue || field.value}
        onFocus={onFocus}
        startAdornment={<InputAdornment position="start">{symbol}</InputAdornment>}
        inputProps={{
          maxLength,
        }}
        gridArea="input"
        onChange={e => handleOnChange(e, field.onChange)}
        disabled={disabled}
        placeholder={placeholder}
        sx={styles.input}
        error={!!(meta.touched && meta.error)}
      />
    )
  }

  return (

    <Field {...restProps}>
      {({ field, meta }) => (
        <Box sx={styles.container} style={style}>
          {renderLabel()}
          {renderInput(field, meta)}
          {renderError(meta)}
        </Box>
      )}
    </Field>

  )
}

export default StyledInput
