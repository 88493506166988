import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Please, enter full name'),
    email: Yup.string().email('Please, enter valid email').required('Please, enter email'),
    subject: Yup.string(),
    message: Yup.string().required('Please, enter message'),
})

export default validationSchema
