import React from 'react'
import { Box, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import SearchIcon from '../../assets/images/search_icon.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'

const Header = () => {
  const classes = useStyles()
  const tenantConfig = useSelector(selectTenantConfig)
  const navigate = useNavigate()

  return (
      <Box className={classes.header} component={'header'}>
        <img
          onClick={() => navigate('/')}
          className={classes.logoImg}
          src={tenantConfig.logoUrl}
          alt={tenantConfig.companyName}
        />
        <Box className={classes.headerMenu}>
          <Box className={classes.searchBox}>
            <SearchInput
              placeholder={'Search'}
            />
            <img src={SearchIcon} alt={'Search'}/>
          </Box>
          <Link
            color="#1989FB"
            href="/"
            underline="hover"
          >
            Merchant Sign-up >
          </Link>
          <Link
            color="#1989FB"
            href="/"
            underline="hover"
          >
            Merchant Login >
          </Link>
        </Box>
      </Box>
  )
}

export default Header

export const SearchInput = styled('input')`
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  font-size: 20px;
  color: #D1D1D6;
  ::placeholder {
    color: #D1D1D6;
  }
`

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
  },
  logoImg: {
    width: 170,
    height: 27,
    transition: 'all ease 0.2s',
    cursor: 'pointer',
    objectFit: 'contain',
  },
  headerMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  searchBox: {
    maxWidth: '280px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    background: '#F2F3F7',
    padding: '13px 20px',
    outline: 'none',
    borderRadius: '8px',
    fontSize: '20px',
  },
}))
