import { ROW_DATA_TYPES } from './typeMappings'

export const beneficiaryOwnerTable = [
  {
    field: 'first_name',
    label: 'First Name',
  },
  {
    field: 'last_name',
    label: 'Last Name',
  },{
    field: 'role',
    label: 'Role',
  },{
    field: 'percentage_of_ownership',
    label: '% of ownership',
  },{
    field: 'home_address',
    label: 'Home Address',
  },{
    field: 'city',
    label: 'City',
  },{
    field: 'state',
    label: 'State',
  },{
    field: 'zip',
    label: 'Zip',
  },{
    field: 'mailing_address',
    label: 'Mailing Address (if Different)',
  },{
    field: 'mailing_city',
    label: 'Mailing City',
  },{
    field: 'mailing_state',
    label: 'Mailing State',
  },{
    field: 'mailing_zip',
    label: 'Mailing Zip',
  },{
    field: 'dob',
    label: 'DOB',
    type: ROW_DATA_TYPES.DATE_WITHOUT_TIME,
  },{
    field: 'ssn',
    label: 'SSN',
  }]
