import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_API_URL}/api`

export const authInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

authInstance.interceptors.request.use(
  function (request) {
    const token = localStorage.getItem('token')
    if (token) {
      request.headers.Authorization = 'Bearer ' + token
    }

    return request
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

authInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true
      window.location.reload()
    }
    return Promise.reject(error)
  },
)
