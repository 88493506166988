import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Box, Button, Typography } from '@mui/material'

import styles from "./styles";
import { formStyles } from '../Form/styles'
import StyledInput from '../StyledComponents/StyledInput/StyledInput'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantConfig } from '../../store/reducers/tenant.reducer'
import * as Yup from 'yup'
import moment from 'moment/moment'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { registerUserWallet } from '../../api/onboarding'
import { showErrorSnackbar } from '../../store/reducers/notification.reducer'
import { returnAxiosError } from '../../utils'

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required('First Name is required'),
  last_name: Yup.string()
    .trim()
    .required('Last Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phone_number: Yup.mixed()
    .test('phone_number', 'Invalid phone number format', (phone_number) => {
      return phone_number && isValidPhoneNumber(phone_number)
    }),
  country: Yup.string()
    .trim()
    .required('Country is required'),
  birth_date: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .min(moment().subtract(120, 'years').toDate(), 'Invalid Date')
    .max(
      moment().subtract(18, 'years').subtract(1, 'days').toDate(),
      'You should be at least 18 y.o.')
    .required('Birth date is required'),
  ssn: Yup.string().when('country', {
    is: (country) => {
      const lowercaseCountry = country ? country.toLowerCase() : '';
      const allowedCountries = ['united states of america', 'usa', 'us'].map(c => c.toLowerCase());

      return allowedCountries.includes(lowercaseCountry);
    },
    then: Yup.string()
      .required('Government Issued ID Number is required'),
    otherwise: Yup.string(),
  }),
  referrer: Yup.string()
    .trim()
    .required('Referral Name is required'),
})

const RegisterWalletForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const tenant = useSelector(selectTenantConfig)

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const result = await registerUserWallet({
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        birth_date: moment(values.birth_date).toISOString(),
        country: values.country,
        ssn: values.ssn,
        referrer: values.referrer,
      })
      if (result) {
        setIsSubmitted(true)
      }
    } catch (e) {
      dispatch(showErrorSnackbar({
        message: returnAxiosError(e),
      }))
    } finally {
      setLoading(false)
    }
  }

  if (isSubmitted) {
    return (
      <Box sx={styles.container}>
        <Typography sx={{ color:'black', fontSize: 25, textAlign: 'center', mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>{`Thank you for the registration. \nWe will send all of the information to your email`}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Formik
        initialValues={{
          customer_accepted_policy: false,
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          birth_date: '',
          country: '',
          ssn: '',
          referrer: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={true}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form
            style={{
            height: '100%',
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <img src={tenant.logoUrl} alt={'logo'} style={{ objectFit: 'contain', alignSelf: 'center', height: 50, }} />
                <Typography sx={{ color:'black', fontSize: 20, textAlign: 'center', mt: 2, mb: 1 }}>Registration</Typography>
                <Box sx={{ overflow: 'auto', height: '100%' }}>
                  <Box sx={{ padding: '8px 8px 0', }}>
                    <Box sx={formStyles.formBlock(1)}>
                      <StyledInput label='First Name' name="first_name" placeholder="First name" required />
                      <StyledInput label='Middle Name' name="middle_name" placeholder="Middle name" />
                      <StyledInput label='Last Name' name="last_name" placeholder="Last name" required />
                      <StyledInput label='Email' name="email" type="email" placeholder="Email" required />
                      <StyledInput label='Phone number' name="phone_number" type="phone" placeholder="Phone number" required />
                      <StyledInput label='Date Of Birth' type="date" name="birth_date" placeholder="Date Of Birth" required />
                      <StyledInput label='Country or Residence' name="country" placeholder="Country" required />
                      {['united states of america', 'usa', 'us'].includes(props.values.country?.toLowerCase()) ?
                        <StyledInput label='SSN' name="ssn" type="number" placeholder="SSN" required /> : null}
                      <StyledInput label='Referral Partner Name' name="referrer" placeholder="How did you hear about us?" required />
                    </Box>
                  </Box>
                <Typography sx={{ color:'black', fontSize: 12, textAlign: 'justify', mt: 2 }}>
                  {`By checking this box, you are giving explicit consent to third-party verification of the accuracy of 
                  the information which you have willingly and knowingly provided on your ${tenant.companyName} Application.`}
                </Typography>
                <Typography sx={{ color:'black', fontSize: 12, textAlign: 'justify', mt: 2 }}>
                  {`The ${tenant.companyName} Anti-Money Laundering & Counter-Terrorism Financing Policy (“AML/CTF Policy”) 
                  sets out the processes and controls to help us prevent, identify, manage and mitigate risks of us being 
                  involved in any kind of illegal activity, in particular money laundering by check this box:`}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  <Field type="checkbox" name="customer_accepted_policy"/>
                  <Typography sx={{ ml: 1, color:'black', fontSize: 12, textAlign: 'justify', mt: 0.5, textDecoration: 'underline' }}>{`Agree to ${tenant.companyName} AML/CTF Policy`}</Typography>
                </Box>
              </Box>
              <Button
                sx={styles.sendButton}
                variant="contained"
                type="submit"
                disabled={!props.isValid || !props.values.customer_accepted_policy || loading}
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default RegisterWalletForm
