import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from "formik"
import moment from 'moment'
import { Box, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import Loader from '../Loader/Loader'

import { getFormData, saveFormData, selectFormData } from '../../store/reducers/form.reducer'

import { mergeObject } from '../../helpers'
import { onboardingFormSteps } from '../../constants/tabs'
import {
  corporateInformationInitialValues,
  locationInformationInitialValues,
  underwritingProfileInitialValues,
  bankAccountInformationInitialValues,
  documentsInitialValues,
  beneficiaryOwnersInitialValues,
} from './formData'

import styles from "./styles";
import NavigationPanel from "../NavigationPanel/NavigationPanel"
import { QontoConnector, QontoStepIcon } from '../Stepper/CustomStepper'
import { FormHandler } from './FormHandler'
import useDisabled from '../../hooks/useDisabled'
import { STEP_TYPES } from '../../constants/typeMappings'
import { selectDocumentsErrors } from '../../store/reducers/documents.reducer'

const MerchantForm = () => {
  const steps = onboardingFormSteps
  const documentsErrors = useSelector(selectDocumentsErrors)
  const [isLoading, setIsLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const existingForm = useSelector(selectFormData)
  const isFormSubmitted = useDisabled()
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      await getExistingForm()
    })()
  }, [])

  useEffect(() => {
    if (!isFormSubmitted) return

    setAllStepsCompleted()
    setActiveStep(steps.length - 1)
  }, [isFormSubmitted])


  const setAllStepsCompleted = () => {
      let allCompleted = {}
      for (let i = 0; i < steps.length; i++) allCompleted[i] = true
      setCompleted(allCompleted)
  }

  const getExistingForm = async () => {
    try {
      await dispatch(getFormData())
    } finally {
      setIsLoading(false)
    }
  }

  const initialValues = {
    ...mergeObject({
      ...corporateInformationInitialValues,
      ...locationInformationInitialValues,
      ...underwritingProfileInitialValues,
      ...bankAccountInformationInitialValues,
      ...beneficiaryOwnersInitialValues,
      ...documentsInitialValues,
      last_opened_step: steps[0].type,
    }, (existingForm || {}))
  }

  const renderStepContent = () => {
    const { ContentComponent } = steps[activeStep]

    return ContentComponent && (
        <Box overflow="auto" flex={1} paddingTop="10px">
          <ContentComponent />
        </Box>
    )
  }

  const validateStepsOnSave = async (values) => {
    const validSteps = {}

    await Promise.all(steps.map( async ({ type, validationSchema }) => {
      if (!validationSchema) {
        validSteps[type] = []
        return
      }

      try {
        validSteps[type] = []
        await validationSchema.validate(values)
      } catch ({ errors }) {
        validSteps[type] = errors
      }
    }))

    validSteps[STEP_TYPES.DOCUMENTS] = Object.keys(documentsErrors).map(key => `${key}: ${documentsErrors[key]}`)

    return validSteps
  }

  const handleSubmit = async (values) => {
    const updatedFormData = { ...values }
    if (values.open_date) updatedFormData.open_date = moment(values.open_date).format('YYYY-MM-DD')

    const { fromTime, toTime } = values.business_work_hours
    if (fromTime) updatedFormData.business_work_hours.fromTime = moment(fromTime).toISOString(true)
    if (fromTime) updatedFormData.business_work_hours.toTime = moment(toTime).toISOString(true)

    const errors = await validateStepsOnSave(values)

    let payload = {}

    Object.keys(errors).forEach((key) => {
      if (errors[key]?.length) return

      switch (key) {
        case STEP_TYPES.DOCUMENTS:
          payload = {
            ...payload,
            documents: updatedFormData.documents,
          }
          break
        case STEP_TYPES.BENEFICIARY_OWNER:
          payload = {
            ...payload,
            beneficiary_owners: updatedFormData.beneficiary_owners,
            is_not_applicable: updatedFormData.is_not_applicable,
          }
          break
        case STEP_TYPES.UNDERWRITING_PROFILE:
          payload = {
            ...payload,
            business_type: updatedFormData.business_type,
            products_sold: updatedFormData.products_sold,
            return_policy: updatedFormData.return_policy,
            avg_ticket: updatedFormData.avg_ticket,
            highest_ticket: updatedFormData.highest_ticket,
            discount_paid: updatedFormData.discount_paid,
            // processor_url: updatedFormData.processor_url,
            // processor_name: updatedFormData.processor_name,
            customer_countries: updatedFormData.customer_countries,
            high_volume_month: updatedFormData.high_volume_month,
            // method_of_sales: updatedFormData.method_of_sales,
            value_of_products_sold: updatedFormData.value_of_products_sold,
            business_work_hours: updatedFormData.business_work_hours,
          }
          break
        case STEP_TYPES.LOCATION_INFO:
          payload = {
            ...payload,
            dba: updatedFormData.dba,
            location_address_1: updatedFormData.location_address_1,
            location_city: updatedFormData.location_city,
            location_zip_code: updatedFormData.location_zip_code,
            location_address_2: updatedFormData.location_address_2,
            location_state: updatedFormData.location_state,
            location_country: updatedFormData.location_country,
            open_date: updatedFormData.open_date,
            customer_service_phone_number: updatedFormData.customer_service_phone_number,
            location_phone_number: updatedFormData.location_phone_number,
            location_email: updatedFormData.location_email,
            location_website: updatedFormData.location_website,
            location_contact_name: updatedFormData.location_contact_name,
            location_contact_phone_number: updatedFormData.location_contact_phone_number,
            location_comments: updatedFormData.location_comments,
          }
          break
        case STEP_TYPES.CORPORATE_INFO:
          payload = {
            ...payload,
            ownership_type: updatedFormData.ownership_type,
            business_name: updatedFormData.business_name,
            business_address_1: updatedFormData.business_address_1,
            business_city: updatedFormData.business_city,
            business_zip_code: updatedFormData.business_zip_code,
            business_address_2: updatedFormData.business_address_2,
            business_state: updatedFormData.business_state,
            business_country: updatedFormData.business_country,
            corporate_email: updatedFormData.corporate_email,
            contact_title: updatedFormData.contact_title,
            contact_name: updatedFormData.contact_name,
            contact_email: updatedFormData.contact_email,
            general_comments: updatedFormData.general_comments,
            federal_tax_id: updatedFormData.federal_tax_id,
            name: updatedFormData.name,
            title: updatedFormData.title,
            email: updatedFormData.email,
            phone_number: updatedFormData.phone_number,
            address_1: updatedFormData.address_1,
            city: updatedFormData.city,
            zip_code: updatedFormData.zip_code,
            address_2: updatedFormData.address_2,
            state: updatedFormData.state,
            country: updatedFormData.country,
          }
          break
        default: payload = updatedFormData
          break
      }
    })

    await dispatch(saveFormData({ data: payload }))
  }

  const validateStep = async (formik) => {
    const errors = await formik.validateForm()

    if (!Object.keys(errors).length && activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
      return true
    }
    return false
  }

  if (isLoading) return <Loader />

  return (
      <Box sx={styles.container}>
        <Formik
            initialValues={initialValues}
            validationSchema={steps[activeStep]?.validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
        >
          <FormHandler
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              validateStep={validateStep}
              setCompleted={setCompleted}
              isDisabled={isFormSubmitted}
              steps={steps}
          >
          <Form style={{
            height: '100%',
          }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Stepper
                  activeStep={activeStep}
                  sx={styles.stepper}
                  alternativeLabel
                  nonLinear
                  connector={<QontoConnector />}
              >
                {steps.map((s, index) => (
                      <Step key={s.type} completed={completed[index]}>
                        <StepButton
                            completed={completed[index]}
                            sx={styles.stepButton}
                            onClick={() => setActiveStep(index)}
                            disabled={!completed[index - 1]}
                        >
                          <StepLabel sx={styles.stepLabel} StepIconComponent={QontoStepIcon}>
                            {s.label}
                          </StepLabel>
                        </StepButton>
                      </Step>
                  )
                )}
              </Stepper>
              {renderStepContent()}
              <NavigationPanel
                  activeStep={activeStep}
                  onStepChange={setActiveStep}
                  steps={steps}
              />
            </Box>
          </Form>
          </FormHandler>
        </Formik>
      </Box>
  )
}

export default MerchantForm
