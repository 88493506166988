import { createSlice } from '@reduxjs/toolkit';
import { FORM_MODE } from '../../constants/typeMappings'

const SLICE_NAME = 'modals';

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    beneficiaryOwnerModal: {
      visible: false,
      data: {
        type : FORM_MODE.CREATE,
        openedIndex : null,
      },
    },
    commentModal: {
      visible: false,
      data: null,
    },
    generalInstructionsModal: {
      visible: false,
      data: null,
    },
  },
  reducers: {
    setShouldShowBeneficiaryOwnerModal: (state, { payload }) => {
      const { visible, ...data } = payload
      const { type = FORM_MODE.CREATE, openedIndex = null } = data
      state.beneficiaryOwnerModal = { visible, data: {
          type,
          openedIndex,
        } };
    },
    setShouldShowCommentModal: (state, action) => {
      state.commentModal.visible = action.payload;
    },
    setShouldShowGeneralInstructionsModal: (state, action) => {
      state.generalInstructionsModal.visible = action.payload;
    },
  },
});

export const {
  setShouldShowBeneficiaryOwnerModal,
  setShouldShowCommentModal,
  setShouldShowGeneralInstructionsModal,
} = slice.actions;

export const selectShouldShowBeneficiaryOwnerModal = (state) => state[SLICE_NAME].beneficiaryOwnerModal;
export const selectShouldShowCommentModal = (state) => state[SLICE_NAME].commentModal.visible;
export const selectShouldShowGeneralInstructionsModal = (state) => state[SLICE_NAME].generalInstructionsModal.visible;

export const modalsReducer = slice.reducer;
