import React, {useEffect, useState} from "react";
import {ReactComponent as MailIcon} from "../../../assets/images/contacts/mail.svg";
import {ReactComponent as PhoneIcon} from "../../../assets/images/contacts/phone.svg";
import {ReactComponent as LocationIcon} from "../../../assets/images/contacts/location.svg";
import {ReactComponent as PostboxIcon} from "../../../assets/images/contacts/postbox.svg";
import {Box, Button, Typography} from "@mui/material";
import FormField from "./components/form-field";
import {ReactComponent as StarMailImg} from "../../../assets/images/star-mail.svg";
import {makeStyles} from "@mui/styles";
import {SECTION_ID} from "../mappings";
import {useFormik} from "formik";
import validationSchema from "../validation-schema";
import { COMPANIES } from '../../../hooks/useCompany'
import { useSelector } from 'react-redux'
import { selectTenantConfig } from '../../../store/reducers/tenant.reducer'

export const ContactSection = () => {
    const [error, setError] = useState('')
    const classes = useStyles({ error })
    const tenantConfig = useSelector(selectTenantConfig)


    const getEmail = () => {
        if (tenantConfig.companyName === COMPANIES.ZERO_LEDGER) {
            return 'info@zeroledger.co'
        }
        if (tenantConfig.companyName === COMPANIES.THE_BOX_WALLET) {
            return 'theboxwallet@gmail.com'
        }
        if (tenantConfig.companyName === COMPANIES.THE_ZERO_WALLET) {
            return 'support@thezerowallet.com'
        }
        return 'info@iatmgroup.com'
    }

    const getPhone = () => {
        if (tenantConfig.companyName === COMPANIES.THE_BOX_WALLET) {
            return '+1 (310) 383-9977'
        }
        return '+1 (817) 819-0211'
    }

    const getLocation = () => {
        if (tenantConfig.companyName === COMPANIES.THE_BOX_WALLET) {
            return '853 9th street, Santa Monica CA 90403'
        }
        return 'Hurst TX 76053'
    }

    const getPostbox = () => {
        if (tenantConfig.companyName === COMPANIES.THE_BOX_WALLET) {
            return null
        }
        return 'P.O. Box 1281'
    }

    const onSubmit = (values) => {
        const { email, message, subject } = values
        window.location.href = `mailto:${getEmail()}?subject=${subject}&body=${message}`
    }

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            subject: '',
            message: '',
        },
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit,
        validationSchema,
    })

    useEffect(() => {
        if (formik.isSubmitting) return;
        const errorField = Object.keys(formik.errors)[0]
        const error = errorField && formik.errors[errorField]
        if (!error) return;

        setError(error)
    }, [formik.isSubmitting])

    const renderError = () =>  error && (
            <Box className={classes.errorContainer}>
                {error}
            </Box>
    )

    const handleButtonClick = () => {
        setError('')
    }

    const contacts = [
        {
            Icon: MailIcon,
            value: getEmail(),
        },
        {
            Icon: PhoneIcon,
            value: getPhone(),
        },
        {
            Icon: LocationIcon,
            value: getLocation(),
        },
        {
            Icon: PostboxIcon,
            value: getPostbox(),
        },
    ]

    return (
        <Box className={classes.contactSection} id={SECTION_ID.CONTACT}>
            <Box className={classes.part}>
                <Typography className={classes.title}>Send us a Message</Typography>
                {renderError()}
                <form className={classes.info} onSubmit={formik.handleSubmit}>
                    <Box className={classes.row}>
                        <FormField
                            label="Full Name"
                            placeholder="Enter your full name"
                            name="fullName"
                            formik={formik}
                            required
                        />
                        <FormField
                            label="Email"
                            placeholder="Enter your email"
                            name="email"
                            type="email"
                            formik={formik}
                            required
                        />
                    </Box>
                    <FormField
                        label="Subject"
                        placeholder="Enter subject"
                        name="subject"
                        formik={formik}
                    />
                    <FormField
                        label="Message"
                        placeholder="Enter your message"
                        name="message"
                        formik={formik}
                        component="textarea"
                        required
                    />
                    <Button
                        className={classes.sendButton}
                        variant="contained"
                        onClick={handleButtonClick}
                        type="submit"
                    >
                        Send Message
                    </Button>
                </form>
            </Box>
            <Box className={`${classes.part} ${classes.contactInfo}`}>
                <Typography className={classes.title}>Contact Information</Typography>
                <Box className={classes.info} gap="30px">
                    {contacts.map(({Icon, value}) => {
                        if (!value) return null
                        return (
                          <Box display="flex" gap="16px">
                              <Icon />
                              <Typography>{value}</Typography>
                          </Box>
                        )
                    })}
                </Box>
                <StarMailImg className={classes.starMailImg} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    contactSection: {
        marginTop: 380,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'space-between',
        width: '100%',
        gap: 32,
        [theme.breakpoints.down(1000)]: {
            gridTemplateColumns: '1fr',
            justifyItems: 'center',
            marginTop: 72,
        },
    },
    title: {
        '&.MuiTypography-root': {
            color: '#282E32',
            fontFamily: 'Jeko-Black',
            fontSize: 32,
            lineHeight: '29px',
            marginBottom: 20,
        },
    },
    info: {
        marginTop: 20,
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        position: 'relative',
    },
    sendButton: {
        width: '100%',
        maxWidth: 150,
        alignSelf: 'flex-end',
    },
    starMailImg: {
        position: 'absolute',
        right: 0,
        bottom: -50,
    },
    part: {
        width: '100%',
        maxWidth: 580,
    },
    contactInfo: {
        position: 'relative',
        justifySelf: 'flex-end',
        [theme.breakpoints.down(1000)]: {
            justifySelf: 'center',
        },
    },
    errorContainer: {
        color: '#842029',
        backgroundColor: '#f8d7da',
        borderColor: '#f5c2c7',
        width: '100%',
        borderRadius: '0.25rem',
        boxSizing: 'border-box',
        padding: 16,
        transition: 'all ease 0.3s',
    },
    row: {
        display: 'flex',
        gap: 20,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}))
