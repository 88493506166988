
const styles = {
  container: {
    height: '100%',
    backgroundColor: 'white',
    maxWidth: 700,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid gray',
    borderRadius: 2,
    overflow:'hidden',
    alignSelf: 'center',
    justifyContent: 'center',
    py: 2,
    px: 5,
    mb: 3,
  },
  stepButton: {
    textTransform: 'uppercase',
    '&.MuiStepButton-root': {
      padding: '24px 0',
      margin: '-24px 0',
    },
    transition: 'border-bottom ease 0.1s',
  },
  stepLabel: {
    maxWidth: '160px',
    height: '50px',
    boxSizing: 'border-box',
    '& :nth-child(2) > span': {
      marginTop: '2px',
    }
  },
  stepper: {
    width: '100%',
    padding: '16px 0',
    height: 'min-content',
  },
  sendButton: {
    width: '100%',
    maxWidth: 150,
    alignSelf: 'center',
    mt: 2,
},
}

export default styles

const formGap = {
  display: 'grid',
  rowGap: 1.5,
  columnGap: 3,
}

export const formStyles = {
  formBlock: (columnCount = 0, gap = 0) => ({
    ...formGap,
    ...(columnCount && { gridTemplateColumns: `repeat(${columnCount}, 1fr)`}),
    ...(gap && { gap }),
  }),
  formRow: (rowCount = 0, gap = 0) => ({
    ...formGap,
    ...(rowCount && { gridTemplateRows: `repeat(${rowCount}, 1fr)`}),
    ...(gap && { gap }),
  })
}

