import {Button} from "@mui/material";
import styles from "./styles";

const StyledButton = (props) => {
  const { children, severity = 'common', sx = {}, ...restProps } = props


  return (
    <Button {...restProps} sx={{...styles.button(severity), ...sx}}>
      {children}
    </Button>
  )
}


export default StyledButton
