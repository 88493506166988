const quillStyles = {
  root: {
    '& .ql-container': {
      height: 100,
      maxHeight: 150,
    }
  }
}

export default quillStyles
