import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ListItem, ListItemIcon, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'

export const DrawerListItem = ({
    path,
    Icon,
    label,
    isVisible = true,
    onClick,
    isActive,
    }) => {
    const navigate = useNavigate()
    const classes = useStyles({ isActive })

    const handleClick = () => {
        if (onClick) onClick()
        if (path) navigate(path)
    }

    return (
        <ListItemStyled button onClick={handleClick} data-is-visible={isVisible}>
            <ListItemIcon className={classes.icon}>
                <Icon style={{ minWidth: 10 }} />
            </ListItemIcon>
            <Typography className={classes.label}>{label}</Typography>
        </ListItemStyled>
    )
}

const ListItemStyled = styled(ListItem)`
  padding: 19px 26px;
  box-sizing: border-box;
  height: 56px;
  &[data-is-visible='false'] {
    display: none;
  }
`
const useStyles = makeStyles((theme) => ({
    label: ({ isActive }) => ({
        '&.MuiTypography-root': {
            color: isActive && theme.palette.primary.main,
            fontSize: 16,
            whiteSpace: 'nowrap',
        },
    }),
    icon: ({ isActive }) => ({
        '&.MuiListItemIcon-root': {
            minWidth: 36,
            color: isActive && theme.palette.primary.main,
        },
    }),
}))
