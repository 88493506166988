import { authInstance } from './authInstance'
import { nonAuthInstance } from './nonAuthInstance'

export const sendForm = async (payload, submit) => {
  try {
    const { data } = await authInstance.post('/onboarding', { payload, submit })
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getForm = async () => {
  try {
  const { data } = await authInstance.get('/onboarding')
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getTenant = async () => {
  try {
    const { data } = await nonAuthInstance.get('/onboarding/tenant')
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const registerUserWallet = async (payload) => {
  try {
    const { data } = await nonAuthInstance.post('/onboarding/register', payload)
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const loginUserWallet = async (payload) => {
  try {
    const { data } = await nonAuthInstance.post('/onboarding/login', payload)
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}


export const uploadDocument = async (formData) => {
  try {
    const { data } = await authInstance.post('/onboarding/upload', formData)
    return data
  } catch (e) {
    console.log(e)
    throw e
  }
}
