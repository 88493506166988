import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTable = (props) => {
  const {
    rows,
    columns,
    renderRows,
    renderColumns,
    sx,
  } = props


  const renderDefaultRows = () => {
    if (renderRows) return renderRows({ rows, columns })

    return rows.map((r, i) => (
      <TableRow
        key={`table-row-${i}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {columns.map(c => {
          return (
            <TableCell component="th" scope="row">
              {r[c.field]}
            </TableCell>
          )
        })}
      </TableRow>
    ))
  }

  const renderDefaultColumns = () => {
    if (renderColumns) return renderColumns({ rows, columns })

    return (
      <TableRow>
        {columns.map(c => <TableCell align="right" key={c.field}>{c.label}</TableCell>)}
      </TableRow>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          {renderDefaultColumns()}
        </TableHead>
        <TableBody>
          {renderDefaultRows()}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StyledTable
