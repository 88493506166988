export const onTokensReceived = (t) => localStorage.setItem('token', t.token)

export const returnAxiosError = (e) => {
  let error = e.message
  if (e?.response?.error) error = e.response.error
  if (e?.response?.data?.type) error = e.response.data.type
  if (e?.response?.data?.message) error = e.response.data.message
  if (e?.response?.data?.error) error = e.response.data.error

  return error
}
