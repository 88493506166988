import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useFormikContext } from 'formik'
import useDisabled from '../../../hooks/useDisabled'
import { requiredDocuments } from '../../../constants'
import Document from './components/Document'
import { useDispatch } from 'react-redux'
import { setDocumentErrors } from '../../../store/reducers/documents.reducer'

const Documents = () => {
  const formik = useFormikContext()
  const isDisabled = useDisabled()

  const dispatch = useDispatch()

  const validateDocuments = () => {
    const errors = {}

    formik?.values?.documents?.forEach(({ id, files }) => {
      const currentDoc = requiredDocuments.find((doc) => doc.id === id)
      if (!currentDoc) return

      const error = currentDoc.required && !files?.length
      if (!error) return

      errors[id] = 'Required Document'
    })

    return errors
  }

  useEffect(() => {
    const errors = validateDocuments()

    if(Object.keys(errors).length) {
      formik.setFieldError('documents', 'Upload all the required documents')
    }

    dispatch(setDocumentErrors(errors))

  }, [formik?.values?.documents])

  if (!formik.values.documents) return null

  return (
    <Box sx={{ height: '100%' }}>
      <Box style={localStyles.documentsListContainer}>
        {requiredDocuments.map((item) =>
            <Document
                {...item}
                parentFormikCtx={formik}
                isDisabled={isDisabled}
            />
        )}
      </Box>
    </Box>
  )
}

const localStyles = {
  documentsListContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 3fr)',
    columnGap: '16px',
    maxWidth: 1300,
    margin: '0 auto',
  },
}

export default Documents
