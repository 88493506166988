import { createSlice } from '@reduxjs/toolkit';
import { getForm, sendForm } from '../../api/onboarding'
import { showErrorSnackbar, showSuccessSnackbar } from './notification.reducer'
import { returnAxiosError } from '../../utils'
import { setBeneficiaryOwners } from './beneficiaryOwners.reducer'

const SLICE_NAME = 'form';

export const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    comment: null,
    data: null,
    open_merchant_form_guid: null,
    status: 'CREATED',
  },
  reducers: {
    setFormState: (state, action) => {
      state.data = action.payload.data;
      state.comment = action.payload.comment;
      state.open_merchant_form_guid = action.payload.open_merchant_form_guid;
      state.status = action.payload.status;
    },
    setFormData: (state, action) => {
      state.data = action.payload
    }
  },
});

export const {
  setFormState,
} = slice.actions;

export const selectFormData = (state) => state[SLICE_NAME].data
export const selectFormStatus = (state) => state[SLICE_NAME].status
export const selectFormComment = (state) => state[SLICE_NAME].comment


const updateFormState = (payload) => async (dispatch) => {
  dispatch(setFormState({
    comment: payload.comment,
    data: payload.data,
    open_merchant_form_guid: payload.open_merchant_form_guid,
    status: payload.status,
  }))
  dispatch(setBeneficiaryOwners(payload.data?.beneficiary_owners || []))
}

export const getFormData = () => async (dispatch) => {
  try {
    const response = await getForm()
    if (!response) return

    dispatch(updateFormState(response))
  } catch (e) {
    dispatch(showErrorSnackbar({
      message: returnAxiosError(e),
    }))
  }
};

export const saveFormData = ({ data }) => async (dispatch, getState) => {
  try {
    const beneficiary_owners = getState().beneficiaryOwners.beneficiaryOwners
    const updatedData = {
      ...data,
      beneficiary_owners,
    }

    const response = await sendForm(updatedData, false)
    await dispatch(updateFormState(response))
    await dispatch(showSuccessSnackbar({
      message: 'Form has been saved',
    }))
  } catch (e) {
    dispatch(showErrorSnackbar({
      message: returnAxiosError(e),
    }))
    throw e
  }
};

export const submitFormData = ({ data }) => async (dispatch) => {
  try {
    const response = await sendForm(data, true)
    dispatch(updateFormState(response))
    dispatch(showSuccessSnackbar({
      message: 'Form has been submitted',
    }))
  } catch (e) {
    dispatch(showErrorSnackbar({
      message: returnAxiosError(e),
    }))
  }
}

export const formReducer = slice.reducer;
